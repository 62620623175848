import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import {
  PingButton,
  PingTextInput as PingInput,
  PingSelectInput as PingSelect,
  PingTextarea as PingTextArea,
  PingLucideIcon,
  PingSearchQueryBuilder,
  TEXT_SEGMENT_KEY,
  PingMultiCheckbox,
} from "@repo/ping-react-js";
import { PingVisionModal } from "../../components/modals/PingVisionModal";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "utils/redux";
import {
  useCreateNavFilterMutation,
  useUpdateNavFilterMutation,
  useDeleteNavFilterMutation,
  useGetNavQuery,
} from "services/pvSlice";
import { useGetNav } from "features/submission-dashboard/queries";
import { UI_VIEW_TYPES } from "constants/index";
import { useGetAdvancedSearchFields } from "features/submission-dashboard/queries";

import "./CustomViewManagementPage.scss";


// WIP file
const CustomViewManagementPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { data: navData, refetch: refetchNav } = useGetNav();
  const [createNavFilter, { isLoading: isCreating }] = useCreateNavFilterMutation();
  const [updateNavFilter, { isLoading: isUpdating }] = useUpdateNavFilterMutation();
  const [deleteNavFilter, { isLoading: isDeleting }] = useDeleteNavFilterMutation();
  
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  // State is managed via isEditModalOpen instead
  
  // Form state
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("Filter");
  const [advancedFilters, setAdvancedFilters] = useState<Record<string, any>>({
    text: "",
    readiness: "",
    readiness__gt: "",
    readiness__lt: "",
    readiness_operator: "equal",
    claimed_by_id: "",
    workflow_status_id: "",
    team_id: ""
  });
  const [sortField, setSortField] = useState<"inception_date" | "created_time">("created_time");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  
  // Get data from store
  const settings = useAppSelector((state) => state.settings.settings);
  const teams = settings?.teams;
  const workflowStatuses = settings?.submission_status;
  const customViews = navData?.views?.filter((view) => view.view_type === "USER_CUSTOM") || [];

  const { advancedSearchFields } = useGetAdvancedSearchFields();
  
  const resetForm = () => {
    setName("");
    setDescription("");
    setIcon("Filter");
    setAdvancedFilters({
      text: "",
      readiness: "",
      readiness__gt: "",
      readiness__lt: "",
      readiness_operator: "equal",
      claimed_by_id: "",
      workflow_status_id: [],
      team_id: []
    });
    setSortField("created_time");
    setSortDirection("desc");
    setSelectedFilter(null);
  };
  
  const loadFilterForEditing = (filter: any) => {
    setSelectedFilter(filter);
    setName(filter.name);
    setDescription(filter.description || "");
    setIcon(filter.icon || "Filter");
    
    // Parse the filter object
    const filterObj = filter.filter || {};
    const newAdvancedFilters: Record<string, any> = {
      text: "",
      readiness: "",
      readiness__gt: "",
      readiness__lt: "",
      readiness_operator: "equal",
      claimed_by_id: "",
      workflow_status_id: [],
      team_id: []
    };
    
    // Extract values from the filter
    Object.entries(filterObj).forEach(([key, value]) => {
      if (key === 'readiness') {
        newAdvancedFilters.readiness = value;
        newAdvancedFilters.readiness_operator = "equal";
      } else if (key === 'readiness__gt') {
        newAdvancedFilters.readiness__gt = value;
        newAdvancedFilters.readiness_operator = "greater_than";
      } else if (key === 'readiness__lt') {
        newAdvancedFilters.readiness__lt = value;
        newAdvancedFilters.readiness_operator = "less_than";
      } else if (key === 'workflow_status_id' || key === 'team_id') {
        // Handle workflow_status_id and team_id as arrays
        newAdvancedFilters[key] = Array.isArray(value) ? value : [value];
      } else {
        newAdvancedFilters[key] = value;
      }
    });
    
    setAdvancedFilters(newAdvancedFilters);
    
    // Parse order_by if available
    if (filter.order_by) {
      const [field, direction] = filter.order_by.split(':');
      setSortField(field as "inception_date" | "created_time");
      setSortDirection(direction as "asc" | "desc");
    }
    
    setIsEditModalOpen(true);
  };
  
  const prepareFilterData = () => {
    // Prepare filter criteria with advanced filters - remove empty values
    const filter: Record<string, any> = {};
    
    // Only include non-empty values in the filter
    Object.entries(advancedFilters).forEach(([key, value]) => {
      if (value !== "" && value !== null && value !== undefined) {
        // Special handling for readiness score based on operator
        if (key === 'readiness_operator') {
          // Skip the operator as it's handled with the readiness fields
          return;
        } else if (key === 'readiness' && value && advancedFilters.readiness_operator === 'equal') {
          filter.readiness = parseInt(value as string, 10);
        } else if (key === 'readiness__gt' && value && (advancedFilters.readiness_operator === 'greater_than' || advancedFilters.readiness_operator === 'range')) {
          filter.readiness__gt = parseInt(value as string, 10);
        } else if (key === 'readiness__lt' && value && (advancedFilters.readiness_operator === 'less_than' || advancedFilters.readiness_operator === 'range')) {
          filter.readiness__lt = parseInt(value as string, 10);
        } else if (key === 'workflow_status_id' || key === 'team_id') {
          // Handle workflow_status_id and team_id as arrays
          // If it's an array and has items, include it
          if (Array.isArray(value) && value.length > 0) {
            filter[key] = value.map(v => parseInt(v, 10));
          }
          // If it's a string, convert it to an array with a single item
          else if (typeof value === 'string' && value !== '') {
            filter[key] = [parseInt(value, 10)];
          }
        } else if (!['readiness', 'readiness__gt', 'readiness__lt'].includes(key)) {
          // Include other non-readiness fields
          filter[key] = value;
        }
      }
    });
    
    return {
      name,
      description,
      view_type: 'USER_CUSTOM',
      icon,
      filter,
      order_by: `${sortField}:${sortDirection}`,
    };
  };
  
  const handleCreateView = async () => {
    try {
      const data = prepareFilterData();
      
      // Create the filter with sort configuration
      await createNavFilter(data).unwrap();
      
      // Close modal and reset form
      setIsCreateModalOpen(false);
      resetForm();

      // Show success message
      toast.success("Custom view created successfully");
    } catch (error) {
      console.error("Failed to create custom view:", error);
      toast.error("Failed to create custom view");
    }
  };
  
  const handleUpdateView = async () => {
    if (!selectedFilter) return;
    
    try {
      const data = prepareFilterData();
      
      // Update the filter
      await updateNavFilter({
        slug: selectedFilter.slug,
        data
      }).unwrap();
      
      // Close modal and reset form
      setIsEditModalOpen(false);
      resetForm();

      // Show success message
      toast.success("Custom view updated successfully");
    } catch (error) {
      console.error("Failed to update custom view:", error);
      toast.error("Failed to update custom view");
    }
  };
  
  const handleDeleteView = async () => {
    if (!selectedFilter) return;
    
    try {
      await deleteNavFilter(selectedFilter.slug).unwrap();
      
      // Close modal and reset selection
      setIsDeleteModalOpen(false);
      setSelectedFilter(null);
      
      // Show success message
      toast.success("Custom view deleted successfully");
    } catch (error) {
      console.error("Failed to delete custom view:", error);
      toast.error("Failed to delete custom view");
    }
  };
  
  const handleViewClick = (view: any) => {
 
    // Navigate to the custom view with filter and sort parameters
    history.push(`custom-views/custom-view-${view.slug}`);
  };
  
  return (
    <div className="CustomViewsPage">
      <div className="CustomViewsPage__Header">
        <h1>Custom Views</h1>
        <PingButton
          onClick={() => setIsCreateModalOpen(true)}
          variant="primary"
        >
          <PingLucideIcon iconName="Plus" />
          <span>Create New View</span>
        </PingButton>
      </div>
      
      <div className="CustomViewsPage__Content">
        {customViews.length === 0 ? (
          <div className="CustomViewsPage__EmptyState">
            <PingLucideIcon iconName="Filter" size={48} />
            <h2>No Custom Views</h2>
            <p>Create a custom view to filter submissions based on your criteria.</p>
            <PingButton
              onClick={() => setIsCreateModalOpen(true)}
              variant="primary"
            >
              <PingLucideIcon iconName="Plus" />
              <span>Create New View</span>
            </PingButton>
          </div>
        ) : (
          <div className="CustomViewsPage__Grid">
            {customViews.map((view) => (
              <div key={view.slug} className="CustomViewsPage__Card">
                <div className="CustomViewsPage__Card__Header">
                  <PingLucideIcon iconName={view.icon || "Filter"} />
                  <h3>{view.name}</h3>
                  <div className="CustomViewsPage__Card__Actions">
                    <PingButton
                      onClick={(e) => {
                        e.stopPropagation();
                        loadFilterForEditing(view);
                      }}
                    >
                      <PingLucideIcon iconName="Edit" />
                    </PingButton>
                    <PingButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedFilter(view);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      <PingLucideIcon iconName="Trash2" />
                    </PingButton>
                  </div>
                </div>
                <p>{view.description}</p>
                
                {Object.keys(view?.filter || {}).length > 0 && (
                  <div className="CustomViewsPage__Card__Filters">
                    <h4>Filters:</h4>
                    <ul>
                      {Object.entries(view?.filter || {}).map(([key, value]) => {
                        // Format the display of different filter types
                        let displayValue = '';
                        
                        if (key === 'text') {
                          displayValue = `Contains: ${value}`;
                        } else if (key === 'readiness') {
                          const readinessValue = typeof value === 'object' ? value?.value : value;
                          const operator = typeof value === 'object' ? value?.operator : 'equal';
                          
                          const operatorMap: Record<string, string> = {
                            'equal': '=',
                            'greater_than': '>',
                            'less_than': '<',
                            'between': 'between'
                          };
                          
                          displayValue = `Readiness score ${operatorMap[operator]} ${readinessValue}`;
                        } else if (key === 'claimed_by_id') {
                          const users = settings?.users || [];
                          const user = users.find(u => u.id.toString() === value.toString());
                          displayValue = `User: ${user?.username || value}`;
                        } else if (key === 'workflow_status_id') {
                          if (Array.isArray(value)) {
                            const statusNames = value.map(id => {
                              const status = workflowStatuses?.find(s => s.id.toString() === id.toString());
                              return status?.name || id;
                            }).join(', ');
                            displayValue = `Status: ${statusNames}`;
                          } else {
                            const status = workflowStatuses?.find(s => s.id.toString() === value.toString());
                            displayValue = `Status: ${status?.name || value}`;
                          }
                        } else if (key === 'team_id') {
                          if (Array.isArray(value)) {
                            const teamNames = value.map(id => {
                              const team = teams?.find(t => t.team_id.toString() === id.toString());
                              return team?.team_name || id;
                            }).join(', ');
                            displayValue = `Team: ${teamNames}`;
                          } else {
                            const team = teams?.find(t => t.team_id.toString() === value.toString());
                            displayValue = `Team: ${team?.team_name || value}`;
                          }
                        } else {
                          displayValue = `${key}: ${JSON.stringify(value)}`;
                        }
                        
                        return (
                          <li key={key}>{displayValue}</li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                
                {view.order_by && (
                  <div className="CustomViewsPage__Card__Sort">
                    <h4>Sort:</h4>
                    <p>
                      {(() => {
                        const [field, direction] = view.order_by.split(':');
                        const fieldLabel = field === 'created_time' ? 'Created Date' : 'Inception Date';
                        const directionLabel = direction === 'asc' ? 'Oldest First' : 'Newest First';
                        return `${fieldLabel} (${directionLabel})`;
                      })()}
                    </p>
                  </div>
                )}
                
                <div className="CustomViewsPage__Card__Footer">
                  <div className="CustomViewsPage__Card__Actions">
                    <PingButton
                      variant="normal"
                      onClick={() => handleViewClick(view)}
                    >
                      View Submissions
                    </PingButton>
                    <PingButton
                      variant="normal"
                      onClick={() => {
                        setSelectedFilter(view);
                        loadFilterForEditing(view);
                        setIsEditModalOpen(true);
                      }}
                    >
                      Edit
                    </PingButton>
                    <PingButton
                      variant="normal"
                      onClick={() => {
                        setSelectedFilter(view);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Delete
                    </PingButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      
      {/* Create Modal */}
      <PingVisionModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
          resetForm();
        }}
        title="Create Custom View"
        renderContent={() => (
        <div className="CustomViewsPage__Form">
          <div className="CustomViewsPage__FormGroup">
            <label>Name</label>
            <PingInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter view name"
              required
            />
          </div>
          
          <div className="CustomViewsPage__FormGroup">
            <label>Description</label>
            <PingTextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter view description"
              required
            />
          </div>
          
        
          <div className="CustomViewsPage__FormGroup">
            <label>Advanced Filters</label>
            <div className="CustomViewsPage__AdvancedFilters">
              <div className="CustomViewsPage__FilterOption">
                <label>Contains text</label>
                <PingInput
                  value={advancedFilters.text || ""}
                  onChange={(e) => setAdvancedFilters({...advancedFilters, text: e.target.value})}
                  placeholder="Search text..."
                />
              </div>
              
              <div className="CustomViewsPage__FilterOption">
                <label>Readiness score</label>
                <div className="CustomViewsPage__RangeFilter">
                  <PingSelect
                    value={advancedFilters.readiness_operator || "equal"}
                    onChange={(e) => {
                      const newOperator = e.target.value;
                      // Create a new filters object with the updated operator
                      const updatedFilters = {
                        ...advancedFilters,
                        readiness_operator: newOperator,
                        // Initialize with empty values to ensure TypeScript knows these properties exist
                        readiness: advancedFilters.readiness || "",
                        readiness__gt: advancedFilters.readiness__gt || "",
                        readiness__lt: advancedFilters.readiness__lt || ""
                      };
                      
                      // Clear the appropriate readiness fields based on the selected operator
                      if (newOperator === "equal") {
                        updatedFilters.readiness__gt = "";
                        updatedFilters.readiness__lt = "";
                      } else if (newOperator === "greater_than") {
                        updatedFilters.readiness = "";
                        updatedFilters.readiness__lt = "";
                      } else if (newOperator === "less_than") {
                        updatedFilters.readiness = "";
                        updatedFilters.readiness__gt = "";
                      } else if (newOperator === "range") {
                        updatedFilters.readiness = "";
                      }
                      
                      setAdvancedFilters(updatedFilters);
                    }}
                    options={[
                      { value: "equal", label: "=" },
                      { value: "greater_than", label: ">" },
                      { value: "less_than", label: "<" },
                      { value: "range", label: "Range" },
                    ]}
                  />
                  {advancedFilters.readiness_operator === "equal" && (
                    <PingInput
                      type="number"
                      min="0"
                      value={advancedFilters.readiness || ""}
                      onChange={(e) => setAdvancedFilters({...advancedFilters, readiness: e.target.value})}
                      placeholder="Score value"
                    />
                  )}
                  {advancedFilters.readiness_operator === "greater_than" && (
                    <PingInput
                      type="number"
                      min="0"
                      value={advancedFilters.readiness__gt || ""}
                      onChange={(e) => setAdvancedFilters({...advancedFilters, readiness__gt: e.target.value})}
                      placeholder="Min score value"
                    />
                  )}
                  {advancedFilters.readiness_operator === "less_than" && (
                    <PingInput
                      type="number"
                      min="0"
                      value={advancedFilters.readiness__lt || ""}
                      onChange={(e) => setAdvancedFilters({...advancedFilters, readiness__lt: e.target.value})}
                      placeholder="Max score value"
                    />
                  )}
                  {advancedFilters.readiness_operator === "range" && (
                    <div className="CustomViewsPage__RangeInputs">
                      <PingInput
                        type="number"
                        min="0"
                        value={advancedFilters.readiness__gt || ""}
                        onChange={(e) => setAdvancedFilters({...advancedFilters, readiness__gt: e.target.value})}
                        placeholder="Min value"
                      />
                      <span className="CustomViewsPage__RangeSeparator">to</span>
                      <PingInput
                        type="number"
                        min="0"
                        value={advancedFilters.readiness__lt || ""}
                        onChange={(e) => setAdvancedFilters({...advancedFilters, readiness__lt: e.target.value})}
                        placeholder="Max value"
                      />
                    </div>
                  )}
                </div>
              </div>
              
              <div className="CustomViewsPage__FilterOption">
                <label>User</label>
                <PingSelect
                  value={advancedFilters.claimed_by_id || ""}
                  onChange={(e) => setAdvancedFilters({...advancedFilters, claimed_by_id: e.target.value})}
                  options={[
                    { value: "", label: "All Users" },
                    ...(settings?.users?.map((user) => ({
                      value: user.id.toString(),
                      label: user.username || user.email || user.id,
                    })) || []),
                  ]}
                />
              </div>
              
              <div className="CustomViewsPage__FilterOption">
                <label>Workflow Status</label>
                <PingMultiCheckbox
                  label="Workflow Status"
                  selected={Array.isArray(advancedFilters.workflow_status_id) ? advancedFilters.workflow_status_id : []}
                  onChange={(values) => setAdvancedFilters({...advancedFilters, workflow_status_id: values})}
                  options={[
                    ...(workflowStatuses || []).map((status) => ({
                      value: status.id,
                      label: status.name,
                    })),
                  ]}
                  maintainOrder={true}
                />
              </div>
              
              <div className="CustomViewsPage__FilterOption">
                <label>Team</label>
                <PingMultiCheckbox
                  label="Team"
                  selected={Array.isArray(advancedFilters.team_id) ? advancedFilters.team_id : []}
                  onChange={(values) => setAdvancedFilters({...advancedFilters, team_id: values})}
                  options={[
                    ...(teams || []).map((team) => ({
                      value: team?.team_id,
                      label: team?.team_name,
                    })),
                  ]}
                  maintainOrder={true}
                />
              </div>
            </div>
          </div>
          
          <div className="CustomViewsPage__FormGroup">
            <label>Sort Configuration</label>
            <div className="CustomViewsPage__SortConfig">
              <div className="CustomViewsPage__SortField">
                <label>Sort Field</label>
                <PingSelect
                  value={sortField}
                  onChange={(e) => setSortField(e.target.value as "inception_date" | "created_time")}
                  options={[
                    { value: "created_time", label: "Created Date" },
                    { value: "inception_date", label: "Inception Date" },
                  ]}
                />
              </div>
              <div className="CustomViewsPage__SortDirection">
                <label>Sort Direction</label>
                <PingSelect
                  value={sortDirection}
                  onChange={(e) => setSortDirection(e.target.value as "asc" | "desc")}
                  options={[
                    { value: "desc", label: "Newest First" },
                    { value: "asc", label: "Oldest First" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        )}
        renderFooter={() => (
          <>
            <PingButton
              onClick={() => {
                setIsCreateModalOpen(false);
                resetForm();
              }}
            >
              Cancel
            </PingButton>
            <PingButton
              variant="primary"
              onClick={handleCreateView}
              disabled={!name || !description || isCreating}
            >
              Create
            </PingButton>
          </>
        )}
      />
      
      {/* Edit Modal */}
      <PingVisionModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          resetForm();
        }}
        title="Edit Custom View"
        renderContent={() => (
        <div className="CustomViewsPage__Form">
          <div className="CustomViewsPage__FormGroup">
            <label>Name</label>
            <PingInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter view name"
              required
            />
          </div>
          
          <div className="CustomViewsPage__FormGroup">
            <label>Description</label>
            <PingTextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter view description"
              required
            />
          </div>
          
          <div className="CustomViewsPage__FormGroup">
            <label>Advanced Filters</label>
            <div className="CustomViewsPage__AdvancedFilters">
              <div className="CustomViewsPage__FilterOption">
                <label>Contains text</label>
                <PingInput
                  value={advancedFilters.text || ""}
                  onChange={(e) => setAdvancedFilters({...advancedFilters, text: e.target.value})}
                  placeholder="Search text..."
                />
              </div>
              
              <div className="CustomViewsPage__FilterOption">
                <label>Readiness score</label>
                <div className="CustomViewsPage__RangeFilter">
                  <PingSelect
                    value={advancedFilters.readiness_operator || "equal"}
                    onChange={(e) => {
                      const newOperator = e.target.value;
                      // Create a new filters object with the updated operator
                      const updatedFilters = {
                        ...advancedFilters,
                        readiness_operator: newOperator,
                        // Initialize with empty values to ensure TypeScript knows these properties exist
                        readiness: advancedFilters.readiness || "",
                        readiness__gt: advancedFilters.readiness__gt || "",
                        readiness__lt: advancedFilters.readiness__lt || ""
                      };
                      
                      // Clear the appropriate readiness fields based on the selected operator
                      if (newOperator === "equal") {
                        updatedFilters.readiness__gt = "";
                        updatedFilters.readiness__lt = "";
                      } else if (newOperator === "greater_than") {
                        updatedFilters.readiness = "";
                        updatedFilters.readiness__lt = "";
                      } else if (newOperator === "less_than") {
                        updatedFilters.readiness = "";
                        updatedFilters.readiness__gt = "";
                      } else if (newOperator === "range") {
                        updatedFilters.readiness = "";
                      }
                      
                      setAdvancedFilters(updatedFilters);
                    }}
                    options={[
                      { value: "equal", label: "=" },
                      { value: "greater_than", label: ">" },
                      { value: "less_than", label: "<" },
                      { value: "range", label: "Range" },
                    ]}
                  />
                  {advancedFilters.readiness_operator === "equal" && (
                    <PingInput
                      type="number"
                      min="0"
                      value={advancedFilters.readiness || ""}
                      onChange={(e) => setAdvancedFilters({...advancedFilters, readiness: e.target.value})}
                      placeholder="Score value"
                    />
                  )}
                  {advancedFilters.readiness_operator === "greater_than" && (
                    <PingInput
                      type="number"
                      min="0"
                      value={advancedFilters.readiness__gt || ""}
                      onChange={(e) => setAdvancedFilters({...advancedFilters, readiness__gt: e.target.value})}
                      placeholder="Min score value"
                    />
                  )}
                  {advancedFilters.readiness_operator === "less_than" && (
                    <PingInput
                      type="number"
                      min="0"
                      value={advancedFilters.readiness__lt || ""}
                      onChange={(e) => setAdvancedFilters({...advancedFilters, readiness__lt: e.target.value})}
                      placeholder="Max score value"
                    />
                  )}
                  {advancedFilters.readiness_operator === "range" && (
                    <div className="CustomViewsPage__RangeInputs">
                      <PingInput
                        type="number"
                        min="0"
                        value={advancedFilters.readiness__gt || ""}
                        onChange={(e) => setAdvancedFilters({...advancedFilters, readiness__gt: e.target.value})}
                        placeholder="Min value"
                      />
                      <span className="CustomViewsPage__RangeSeparator">to</span>
                      <PingInput
                        type="number"
                        min="0"
                        value={advancedFilters.readiness__lt || ""}
                        onChange={(e) => setAdvancedFilters({...advancedFilters, readiness__lt: e.target.value})}
                        placeholder="Max value"
                      />
                    </div>
                  )}
                </div>
              </div>
              
              <div className="CustomViewsPage__FilterOption">
                <label>Assigned to</label>
                <PingSelect
                  value={advancedFilters.claimed_by_id || ""}
                  onChange={(e) => setAdvancedFilters({...advancedFilters, claimed_by_id: e.target.value})}
                  options={[
                    { value: "", label: "Anyone" },
                    ...(settings?.users || []).map((user) => ({
                      value: user.id,
                      label: user.username,
                    })),
                  ]}
                />
              </div>
              
              <div className="CustomViewsPage__FilterOption">
                <label>Status</label>
                <PingMultiCheckbox
                  label="Status"
                  selected={Array.isArray(advancedFilters.workflow_status_id) ? advancedFilters.workflow_status_id : []}
                  onChange={(values) => setAdvancedFilters({...advancedFilters, workflow_status_id: values})}
                  options={[
                    ...(workflowStatuses || []).map((status) => ({
                      value: status.id,
                      label: status.name,
                    })),
                  ]}
                  maintainOrder={true}
                />
              </div>
              
              <div className="CustomViewsPage__FilterOption">
                <label>Team</label>
                <PingMultiCheckbox
                  label="Team"
                  selected={Array.isArray(advancedFilters.team_id) ? advancedFilters.team_id : []}
                  onChange={(values) => setAdvancedFilters({...advancedFilters, team_id: values})}
                  options={[
                    ...(teams || []).map((team) => ({
                      value: team?.team_id,
                      label: team?.team_name,
                    })),
                  ]}
                  maintainOrder={true}
                />
              </div>
            </div>
          </div>
          
          <div className="CustomViewsPage__FormGroup">
            <label>Sort Configuration</label>
            <div className="CustomViewsPage__SortConfig">
              <div className="CustomViewsPage__SortField">
                <label>Sort Field</label>
                <PingSelect
                  value={sortField}
                  onChange={(e) => setSortField(e.target.value as "inception_date" | "created_time")}
                  options={[
                    { value: "created_time", label: "Created Date" },
                    { value: "inception_date", label: "Inception Date" },
                  ]}
                />
              </div>
              <div className="CustomViewsPage__SortDirection">
                <label>Sort Direction</label>
                <PingSelect
                  value={sortDirection}
                  onChange={(e) => setSortDirection(e.target.value as "asc" | "desc")}
                  options={[
                    { value: "desc", label: "Newest First" },
                    { value: "asc", label: "Oldest First" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        )}
        renderFooter={() => (
          <>
            <PingButton
              onClick={() => {
                setIsEditModalOpen(false);
                resetForm();
              }}
            >
              Cancel
            </PingButton>
            <PingButton
              variant="primary"
              onClick={handleUpdateView}
              disabled={!name || !description || isUpdating}
              isLoading={isUpdating}
            >
              Update
            </PingButton>
          </>
        )}
      />
      
      {/* Delete Modal */}
      <PingVisionModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setSelectedFilter(null);
        }}
        title="Delete Custom View"
        renderContent={() => (
          <p>
            Are you sure you want to delete the custom view "{selectedFilter?.name}"?
            This action cannot be undone.
          </p>
        )}
        renderFooter={() => (
          <>
            <PingButton
              onClick={() => {
                setIsDeleteModalOpen(false);
                setSelectedFilter(null);
              }}
            >
              Cancel
            </PingButton>
            <PingButton
              onClick={handleDeleteView}
              disabled={isDeleting}
              isLoading={isDeleting}
            >
              Delete
            </PingButton>
          </>
        )}
      />
    </div>
  );
};

export default CustomViewManagementPage;
