type TokenMap = Record<string, string | number>;

type TokenDef = {
  cssPrefix: string;
  values: TokenMap;
};

const baseMultiplier = 0.25;

const fontFamily: TokenDef = {
  cssPrefix: "font-family",
  values: {
    base: `"Macan", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`,
  },
} as const;

type FontFamily = keyof typeof fontFamily.values;

const fontWeight = {
  cssPrefix: "font-weight",
  values: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
} as const;

type FontWeight = keyof typeof fontWeight.values;

const fontSize = {
  cssPrefix: "font-size",
  values: {
    "2xs": `${baseMultiplier * 2.5}rem`,
    xs: `${baseMultiplier * 3}rem`,
    sm: `${baseMultiplier * 3.5}rem`,
    md: `${baseMultiplier * 4}rem`,
    lg: `${baseMultiplier * 4.5}rem`,
    xl: `${baseMultiplier * 5}rem`,
    "2xl": `${baseMultiplier * 6}rem`,
  },
} as const;

type FontSize = keyof typeof fontSize.values;

const lineHeight = {
  cssPrefix: "line-height",
  values: {
    "2xs": `${baseMultiplier * 3}rem`,
    xs: `${baseMultiplier * 4}rem`,
    sm: `${baseMultiplier * 5}rem`,
    md: `${baseMultiplier * 6}rem`,
    lg: `${baseMultiplier * 7}rem`,
    xl: `${baseMultiplier * 8}rem`,
    "2xl": `${baseMultiplier * 9}rem`,
  },
} as const;

type LineHeight = keyof typeof lineHeight.values;

const spacing = {
  cssPrefix: "spacing",
  values: {
    none: 0,
    "0-5": `${baseMultiplier * 0.5}rem`,
    "1": `${baseMultiplier * 1}rem`,
    "1-5": `${baseMultiplier * 1.5}rem`,
    "2": `${baseMultiplier * 2}rem`,
    "2-5": `${baseMultiplier * 2.5}rem`,
    "3": `${baseMultiplier * 3}rem`,
    "4": `${baseMultiplier * 4}rem`,
    "5": `${baseMultiplier * 5}rem`,
    "6": `${baseMultiplier * 6}rem`,
    "8": `${baseMultiplier * 8}rem`,
    "10": `${baseMultiplier * 10}rem`,
  },
} as const;

type Spacing = keyof typeof spacing.values;

const radius = {
  cssPrefix: "radius",
  values: {
    none: 0,
    "0-5": `${baseMultiplier * 0.5}rem`,
    "1": `${baseMultiplier * 1}rem`,
    "1-5": `${baseMultiplier * 1.5}rem`,
    "2": `${baseMultiplier * 2}rem`,
    "2-5": `${baseMultiplier * 2.5}rem`,
    "3": `${baseMultiplier * 3}rem`,
    "3-5": `${baseMultiplier * 3.5}rem`,
    "4": `${baseMultiplier * 4}rem`,
    "5": `${baseMultiplier * 5}rem`,
    "6": `${baseMultiplier * 6}rem`,
    max: `999rem`,
  },
} as const;

type Radius = keyof typeof radius.values;

const size = {
  cssPrefix: "size",
  values: {
    s50: `${baseMultiplier * 1}rem`,
    s100: `${baseMultiplier * 3}rem`,
    s200: `${baseMultiplier * 4}rem`,
    s300: `${baseMultiplier * 5}rem`,
    s400: `${baseMultiplier * 6}rem`,
    s500: `${baseMultiplier * 7}rem`,
    s600: `${baseMultiplier * 8}rem`,
    s700: `${baseMultiplier * 9}rem`,
    s800: `${baseMultiplier * 10}rem`,
    s900: `${baseMultiplier * 11}rem`,
    s1000: `${baseMultiplier * 12}rem`,
    s1100: `${baseMultiplier * 16}rem`,
    s1200: `${baseMultiplier * 20}rem`,
  },
} as const;

type Size = keyof typeof size.values;

const color = {
  cssPrefix: "color",
  values: {
    // Base colors
    white: "hsla(0, 0%, 100%, 1)",
    black: "hsla(0, 0%, 0%, 1)",
    "ping-orange": "hsla(11, 99%, 48%, 1)",

    // Neutral colors
    "neutral-50": "hsla(300, 23%, 97%, 1)",
    "neutral-100": "hsla(276, 12%, 92%, 1)",
    "neutral-200": "hsla(245, 9%, 75%, 1)",
    "neutral-300": "hsla(240, 8%, 63%, 1)",
    "neutral-400": "hsla(240, 7%, 51%, 1)",
    "neutral-500": "hsla(237, 9%, 44%, 1)",
    "neutral-600": "hsla(237, 11%, 38%, 1)",
    "neutral-700": "hsla(238, 21%, 22%, 1)",
    "neutral-800": "hsla(238, 29%, 16%, 1)",
    "neutral-900": "hsla(236, 35%, 8%, 1)",

    // Brand colors
    "brand-50": "hsla(16, 100%, 98%, 1)",
    "brand-100": "hsla(17, 100%, 93%, 1)",
    "brand-200": "hsla(15, 100%, 77%, 1)",
    "brand-300": "hsla(12, 100%, 64%, 1)",
    "brand-400": "hsla(10, 98%, 47%, 1)",
    "brand-500": "hsla(11, 85%, 42%, 1)",
    "brand-600": "hsla(11, 76%, 36%, 1)",
    "brand-700": "hsla(11, 62%, 20%, 1)",
    "brand-800": "hsla(13, 58%, 14%, 1)",

    // Success colors
    "success-50": "hsla(129, 41%, 97%, 1)",
    "success-100": "hsla(143, 45%, 89%, 1)",
    "success-200": "hsla(151, 47%, 63%, 1)",
    "success-300": "hsla(158, 70%, 40%, 1)",
    "success-400": "hsla(154, 46%, 37%, 1)",
    "success-500": "hsla(153, 38%, 33%, 1)",
    "success-600": "hsla(152, 33%, 29%, 1)",
    "success-700": "hsla(148, 22%, 17%, 1)",
    "success-800": "hsla(147, 19%, 12%, 1)",

    // Warning colors
    "warning-50": "hsla(40, 100%, 95%, 1)",
    "warning-100": "hsla(39, 100%, 86%, 1)",
    "warning-200": "hsla(40, 92%, 54%, 1)",
    "warning-300": "hsla(39, 70%, 46%, 1)",
    "warning-400": "hsla(39, 58%, 39%, 1)",
    "warning-500": "hsla(39, 51%, 34%, 1)",
    "warning-600": "hsla(39, 47%, 29%, 1)",
    "warning-700": "hsla(39, 36%, 17%, 1)",
    "warning-800": "hsla(36, 33%, 12%, 1)",

    // Danger colors
    "danger-50": "hsla(354, 100%, 98%, 1)",
    "danger-100": "hsla(354, 100%, 94%, 1)",
    "danger-200": "hsla(352, 100%, 82%, 1)",
    "danger-300": "hsla(350, 90%, 70%, 1)",
    "danger-400": "hsla(345, 74%, 55%, 1)",
    "danger-500": "hsla(343, 72%, 46%, 1)",
    "danger-600": "hsla(345, 63%, 40%, 1)",
    "danger-700": "hsla(349, 48%, 23%, 1)",
    "danger-800": "hsla(349, 44%, 15%, 1)",

    // Translucent colors
    "translucent-200": "hsla(230, 33%, 4%, 0.23)",
    "translucent-700": "hsla(230, 33%, 4%, 0.72)",
    "translucent-800": "hsla(0, 0%, 0%, 0.8)",
    "translucent-900": "hsla(230, 33%, 4%, 0.91)",
  },
} as const;

type Color = keyof typeof color.values;

const iconStroke = {
  cssPrefix: "icon-stroke",
  values: {
    "i0-75": 0.75,
    i1: 1,
    "i1-25": 1.25,
    "i1-5": 1.5,
    "i1-75": 1.75,
    i2: 2,
  },
} as const;

type IconStroke = keyof typeof iconStroke.values;

const borderThickness = {
  cssPrefix: "border-thickness",
  values: {
    none: 0,
    "1px": "1px",
    "2px": "2px",
    "1": `${baseMultiplier}rem`,
  },
} as const;

type BorderThickness = keyof typeof borderThickness.values;

const border = {
  cssPrefix: "border",
  values: {
    "neutral-100":
      "solid var(--ping-border-thickness-1px) var(--ping-color-neutral-100)",
    dashed:
      "dashed var(--ping-border-thickness-1px) var(--ping-color-neutral-300)",
  },
} as const;

type Border = keyof typeof border.values;

const boxShadow = {
  cssPrefix: "box-shadow",
  values: {
    panel: "0 4px 8px rgba(0, 0, 0, 0.06), 0 0 4px rgba(0, 0, 0, 0.04)",
    subtle: "0 1px 1px rgba(0, 0, 0, 0.03), 0 0 1px rgba(0, 0, 0, 0.05)",
    fab: "0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15)",
    tooltip:
      "inset 0 0 2px rgba(0, 0, 0, 0.25), 0 12px 5px rgba(0, 0, 0, 0.01), 0 7px 4px rgba(0, 0, 0, 0.05), 0 3px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.1)",
    modal: "0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08)",
  },
} as const;
type Shadow = keyof typeof boxShadow.values;

const designTokens = [
  color,
  fontFamily,
  fontWeight,
  fontSize,
  lineHeight,
  spacing,
  radius,
  size,
  iconStroke,
  borderThickness,
  border,
  boxShadow,
];

export {
  type TokenMap,
  type TokenDef,
  fontFamily,
  type FontFamily,
  fontWeight,
  type FontWeight,
  fontSize,
  type FontSize,
  lineHeight,
  type LineHeight,
  spacing,
  type Spacing,
  radius,
  type Radius,
  size,
  type Size,
  color,
  type Color,
  iconStroke,
  type IconStroke,
  borderThickness,
  type BorderThickness,
  border,
  type Border,
  boxShadow,
  type Shadow,
  designTokens,
};
