import { z } from "zod";

type NonEmptyArray<T> = [T, ...T[]];

export const DOCUMENT_PROCESSING_STATUS = {
  N: "Not Processed",
  I: "In Progress",
  C: "Completed",
  F: "Failed"
} as const;

export const DOCUMENT_TYPE = {
  // Sync with pingvision/core/constants.py PARSE_DOCUMENT_TYPES
  // order here matters for the tabs in PVAllDocumentsPreviewPanel
  EML: "Email Correspondence",
  ACORD: "ACORD",
  SOV: "SOV",
  LOSS_RUN: "Loss Run",
  SOV_WORKING_FILE: "SOV Working File",
  SOVFIXER_JSON: "SOVFixer JSON",
  SOVFIXER_SCRUBBER: "SOVFixer Scrubber",
  SOVFIXER_OUTPUT: "SOVFixer Output",
  OTHER: "Other",
  UNKNOWN: "Unknown",
  EMAIL_BODY_HTML: "Email Body HTML",
  EMAIL_BODY_TXT: "Email Body Text"
} as const;

export const ACTION_TYPE = {
  download: "download",
  "sovfixer-parse": "run sovfixer",
  rename: "rename",
  archive: "archive",
  preview: "preview",
  unarchive: "unarchive"
} as const;

const sovDataTypeDocumentSchema = z
  .object({
    id: z.number(),
    filename: z.string(),
    document_type: z.enum(Object.keys(DOCUMENT_TYPE) as NonEmptyArray<string>),
    processing_status: z
      .enum(Object.keys(DOCUMENT_PROCESSING_STATUS) as NonEmptyArray<string>)
      .optional(),
    processing_last_message: z.string().nullable().optional(),
    processing_pct_complete: z.number().nullable().optional(),
    created_time: z.string(),
    is_archived: z.boolean(),
    archived_reason: z.string().nullable().optional(),
    archived_on: z.string().nullable().optional(),
    url: z.string(),
    preview_url: z.string().nullable().optional(),
    actions: z.array(z.enum(Object.keys(ACTION_TYPE) as NonEmptyArray<string>)),
    sovfixer_result_status: z.string().nullable().optional(),
    sovfixer_result_message: z.string().nullable().optional()
  })
  .strict();

const sovJobSchema = z
  .object({
    job_id: z.string(),
    filename: z.string(),
    job_type: z.string(),
    job_type_details: z
      .object({
        sovfixer_sovid: z.string().nullable().optional()
      })
      .nullable()
      .optional(),
    processing_last_message: z.string().nullable().optional(),
    processing_pct_complete: z.number().nullable().optional(),
    processing_status: z
      .enum(Object.keys(DOCUMENT_PROCESSING_STATUS) as NonEmptyArray<string>)
      .optional(),
    sovfixer_result_status: z.string().nullable().optional(),
    sovfixer_result_message: z.string().nullable().optional()
  })
  .strict();

export type SovDataTypeDocument = z.infer<typeof sovDataTypeDocumentSchema>;

export type SovJobType = z.infer<typeof sovJobSchema>;

export const triageRuleSchema = z
  .object({
    name: z.string(),
    ran_at: z.string(),
    result: z.string(),
    value: z.string()
  })
  .strict();

export type TriageRule = z.infer<typeof triageRuleSchema>;

export const sovDataSchema = z
  .object({
    id: z.string(),
    is_ping_ready: z.boolean().nullable().optional(),
    actions: z.object({
      transition_to: z.record(z.string()),
      claim: z.boolean().optional(),
      unclaim: z.boolean().optional(),
      download_documents: z.boolean(),
      view_map: z.boolean()
    }),
    company__short_name: z.string(),
    source__source_type: z.string(),
    source__inbox_email_address: z.string().nullable().optional(),
    source__source_type_display: z.string().nullable().optional(),
    division__short_name: z.string(),
    division_id: z.number(),
    team__name: z.string(),
    team_id: z.number(),
    workflow_status_id: z.number(),
    client_ref: z.string().nullable(),
    insured_name: z.string().nullable(),
    claimed_by_id: z.number().nullable(),
    insured_website: z.string().nullable(),
    insured_contact_name: z.string().nullable(),
    insured_contact_email: z.string().nullable(),
    insured_contact_phone: z.string().nullable(),
    insured_sic: z.string().nullable(),
    insured_gl_code: z.string().nullable(),
    insured_naics: z.string().nullable(),

    from_email: z.string().nullable(),
    data_readiness_score: z.number().nullable(),
    data_readiness_notes: z
      .array(
        z.object({
          reason: z.string(),
          severity: z.string().optional(),
          value: z.number().optional()
        })
      )
      .nullable(),
    created_time: z.string(),
    modified_time: z.string(),
    received_time: z.string(),
    documents: z.array(sovDataTypeDocumentSchema),
    jobs: z.array(sovJobSchema),
    global_request_id: z.string().nullable(),
    ping_maps: z
      .object({
        url: z.string().nullable(),
        status: z.string(),
        status_display: z.string(),
        status_reason: z.string(),
        status_pct_complete: z.number()
      })
      .nullable(),
    pk: z.number(),
    extra_data: z.record(z.unknown()).nullable(),
    inception_date: z.string().nullable(),
    expiration_date: z.string().nullable(),
    insured_street: z.string().nullable(),
    insured_street2: z.string().nullable(),
    insured_city: z.string().nullable(),
    insured_state: z.string().nullable(),
    insured_zip: z.string().nullable(),
    insured_fein: z.string().nullable(),
    insured_country: z.string().nullable(),
    insured_business_description: z.string().nullable(),

    home_state: z.string().nullable(),
    broker_name: z.string().nullable(),
    broker_email: z.string().nullable(),
    broker_street: z.string().nullable(),
    broker_street2: z.string().nullable(),
    broker_city: z.string().nullable(),
    broker_state: z.string().nullable(),
    broker_zip: z.string().nullable(),
    broker_fein: z.string().nullable(),
    broker_country: z.string().nullable(),
    broker_phone: z.string().nullable().optional(),
    broker_license_number: z.string().nullable().optional(),
    broker_license_state: z.string().nullable().optional(),
    broker_license_expiry: z.string().nullable().optional(),
    triage_rules_overall_result: z.string().nullable(),
    triage_rule_results: z.array(triageRuleSchema),
    limits_total_limit: z.number().nullable(),
    num_buildings: z.number().nullable(),
    statistics: z.record(z.unknown()).nullable(),
    workflow_status__name: z.string().nullable().optional(),
    automated_processing_failed: z.boolean().nullable().optional()
  })
  .strict();

export type SovDataType = z.infer<typeof sovDataSchema>;

export const sovDataTypePaginatedResponseSchema = z
  .object({
    results: z.array(sovDataSchema),
    cursor_id: z.string().nullable(),
    prev_cursor_id: z.string().nullable(),
    has_remaining: z.boolean().nullable().optional(),
    total_size_without_cursors: z.string().nullable().optional()
  })
  .strict();

export type SovDataTypePaginatedResponse = z.infer<
  typeof sovDataTypePaginatedResponseSchema
>;

export type UserType = {
  id: number;
  username: string;
  full_name: string;
  short_name: string;
  img: string;
};

export type DivisionType = {
  name: string;
  id: number;
  short_name: string;
};

export type CompanyType = {
  name: string;
  id: number;
};

export type TeamType = {
  team_id: number;
  team_name: string;
  company: CompanyType;
  division: DivisionType;
  users: UserType[];
};

export type SubmissionStatusType = {
  name: string;
  id: number;
};
