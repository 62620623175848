import { useEffect } from "react";
import { useDispatch, Provider } from "react-redux";

import {
  useHistory,
  useLocation,
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {
  setAccessToken,
  RouterContext,
  RootWrapper,
  useAuth,
  PingToaster,
} from "@repo/ping-react-js";

import { setupStore } from "services/store";
import { useGetSettingsQuery } from "services/pvSlice";
import { setIsEmployee } from "reducers/settings";
import { useAppSelector } from "utils/redux";
import { NotFoundPage } from "features/misc-pages";
import { FRONT_END_BASE_URL } from "constants/index";
import { VersionChecker } from "./VersionChecker";
import { SubmissionsQueryGlobalProvider } from "../contexts/SubmissionsQueryGlobalContext.tsx";

import PingVisionSubmissionDashboard from "features/submission-dashboard/PingVisionSubmissionDashboard";

const store = setupStore();

// Wrapper component to check team access.
const TeamAccessCheck: React.FC<{
  teamName: string;
  children: React.ReactNode;
}> = ({ teamName, children }) => {
  useGetSettingsQuery({});

  const settings = useAppSelector((state) => state.settings.settings);

  const teams = settings?.teams?.map((t) => t.team_name) || [];

  if (!teams?.length) {
    return null;
  }

  const modifiedTeamName = teamName?.replace(/-/g, " ");

  // Check if user has access to this team

  const hasTeamAccess = teams?.some((team) => team === modifiedTeamName);

  if (!hasTeamAccess) {
    return <NotFoundPage />;
  }

  return <>{children}</>;
};

const PingVisionRoutes = () => {
  const { accessToken, ssoUser } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (accessToken) {
      dispatch(setAccessToken(accessToken));
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    dispatch(
      setIsEmployee(ssoUser?.email?.includes("@pingintel.com") ?? false),
    );
  }, [dispatch, ssoUser]);

  // We want to wait until the `accessToken` is set in redux before rendering
  // the routes. Otherwise, the `prepareHeaders()` function in
  // `fetchBaseQuery()` will not have the `accessToken` available to set the
  // `Authorization` header.
  const reduxAccessToken = useAppSelector((state) => state.auth.accessToken);
  if (!reduxAccessToken) {
    return null;
  }

  /* All new route patterns should be accounted for in the Django backend, in pingvision/urls.py */
  return (
    <RouterContext.Provider value={{ location, history }}>
        <Switch>
          <Route path={`${FRONT_END_BASE_URL}/:slug([a-zA-Z-]+)`} exact>
            <PingVisionSubmissionDashboard />
          </Route>
          <Route path={`${FRONT_END_BASE_URL}/i/:pingId([0-9a-zA-Z-]+)`} exact>
            <PingVisionSubmissionDashboard />
          </Route>
          
          <Route path={`${FRONT_END_BASE_URL}/custom-views`} exact>
            <PingVisionSubmissionDashboard />
          </Route>
          
          <Route path={`${FRONT_END_BASE_URL}/custom-views/:slug([a-zA-Z0-9-_]+)`} exact>
            <PingVisionSubmissionDashboard />
          </Route>

          <Route
            path={`/teams/:teamName/team-members`}
            exact
            render={({ match }) => (
              <TeamAccessCheck teamName={match.params.teamName}>
                <PingVisionSubmissionDashboard />
              </TeamAccessCheck>
            )}
          />

          <Route
            path={`${FRONT_END_BASE_URL}/:teamName/views/:slug([a-zA-Z-]+)`}
            exact
            render={({ match }) => (
              <TeamAccessCheck teamName={match.params.teamName}>
                <PingVisionSubmissionDashboard />
              </TeamAccessCheck>
            )}
          />
          <Route
            path={`${FRONT_END_BASE_URL}/:teamName/views/:slug([a-zA-Z-]+)/:pingId([0-9a-zA-Z-]+)`}
            exact
            render={({ match }) => (
              <TeamAccessCheck teamName={match.params.teamName}>
                <PingVisionSubmissionDashboard />
              </TeamAccessCheck>
            )}
          />
          <Route path="/">
            <Redirect to={`${FRONT_END_BASE_URL}/my-issues`} />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
    </RouterContext.Provider>
  );
};

const Root = () => {
  return (
    <RootWrapper VITE_APP_AUTH_URL={import.meta.env.VITE_APP_AUTH_URL}>
      <Provider store={store}>
        <BrowserRouter>
          <SubmissionsQueryGlobalProvider>
            <PingToaster position="bottom-right" />
            <PingVisionRoutes />
            <VersionChecker />
          </SubmissionsQueryGlobalProvider>
        </BrowserRouter>
      </Provider>
    </RootWrapper>
  );
};

export default Root;
