interface IconProps extends React.SVGAttributes<SVGElement> {
  className?: string;
  size?: string | number;
  color?: string;
  strokeWidth?: string | number;
}

export const PingAreaIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M7 17L17 7M12 17L17 12M7 12L12 7M6 8L8 6M16 18L18 16M5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingHideLandmarkIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M3 22H18M6 18V11M10 18V10M14 18V14M18 11V14M2 2L10 10M22 22L14 14M11.75 7H20L12 2L8.4 4.25M14 14L10 10M7 7H4L5.84615 5.84615"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingElevationProfileIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M14.75 4.5L11.0938 9H7.71875L4.0625 13.5M19 21H6.15146C4.8884 21 4.19081 19.5344 4.98728 18.5541L10.3125 12H13.6875L18.3358 6.27898C19.2264 5.18293 21 5.81264 21 7.22487V19C21 20.1046 20.1046 21 19 21Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Ping3DIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M2.5 14.875C2.5 16.4628 4.067 17.75 6 17.75C7.933 17.75 9.5 16.4628 9.5 14.875C9.5 13.2872 8.10137 11.75 6 11.75M6 11.75C8.11235 11.75 9.5 10.3247 9.5 8.875C9.5 7.42525 7.933 6.25 6 6.25C4.067 6.25 2.5 7.42525 2.5 8.875M6 11.75H5M12.75 6.5H16.75C18.0761 6.5 19.3479 7.07946 20.2855 8.11091C21.2232 9.14236 21.75 10.5413 21.75 12C21.75 13.4587 21.2232 14.8576 20.2855 15.8891C19.3479 16.9205 18.0761 17.5 16.75 17.5H12.75V6.5Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export const PingPlaceholderIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M15 18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18C9 16.3431 10.3431 15 12 15C13.6569 15 15 16.3431 15 18Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M15 6C15 7.65685 13.6569 9 12 9C10.3431 9 9 7.65685 9 6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M18 9C19.6569 9 21 10.3431 21 12C21 13.6569 19.6569 15 18 15C16.3431 15 15 13.6569 15 12C15 10.3431 16.3431 9 18 9Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M6 9C7.65685 9 9 10.3431 9 12C9 13.6569 7.65685 15 6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export const PingElevationIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M11.75 20.25H20.25M11.75 3.75L20.25 3.75M11.75 7.75L16.25 7.75M11.75 16.25H16.25M11.75 12H20.25M5.25 5.75C6.07843 5.75 6.75 5.07843 6.75 4.25C6.75 3.42157 6.07843 2.75 5.25 2.75C4.42157 2.75 3.75 3.42157 3.75 4.25C3.75 5.07843 4.42157 5.75 5.25 5.75ZM5.25 5.75L5.25 18.25M5.25 18.25C4.42157 18.25 3.75 18.9216 3.75 19.75C3.75 20.5784 4.42157 21.25 5.25 21.25C6.07843 21.25 6.75 20.5784 6.75 19.75C6.75 18.9216 6.07843 18.25 5.25 18.25Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingLocationIcon = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M8 16.0142C5.32211 16.393 3.5 17.1402 3.5 18C3.5 19.2426 7.30558 20.25 12 20.25C16.6944 20.25 20.5 19.2426 20.5 18C20.5 17.1402 18.6779 16.393 16 16.0142M12 12C14.4853 12 16.5 9.98528 16.5 7.5C16.5 5.01472 14.4853 3 12 3C9.51472 3 7.5 5.01472 7.5 7.5C7.5 9.98528 9.51472 12 12 12ZM12 12V17"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export const PingAccuracyIconLow = ({
  className,
  size = 24,
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8M22 4V20"
        stroke="#BCBBC6"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20H2.01M7 20V16"
        stroke="#D52B50"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingAccuracyIconMedium = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8M22 4V20"
        stroke="#BCBBC6"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20H2.01M7 20V16M12 20V12"
        stroke="#F6AE1F"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingAccuracyIconHigh = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8M22 4V20"
        stroke="#BCBBC6"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8"
        stroke="#1FAF7B"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingAccuracyIconVeryHigh = ({
  className,
  size = 24,
  color = "currentColor",
  strokeWidth = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8M22 4V20"
        stroke="#BCBBC6"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20H2.01M7 20V16M12 20V12M17 20V8M22 4V20"
        stroke="#1FAF7B"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingNotGeocodedIcon = ({ size = 24 }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
      fill="#ffe7e4ff"
    >
      <path
        d="M8 5.00049V7.66715M8 10.3338H8.00667M14.4866 11.0005L9.15329 1.66714C9.037 1.46194 8.86836 1.29127 8.66457 1.17252C8.46078 1.05378 8.22915 0.991211 7.99329 0.991211C7.75743 0.991211 7.52579 1.05378 7.322 1.17252C7.11822 1.29127 6.94958 1.46194 6.83329 1.66714L1.49995 11.0005C1.38241 11.204 1.32077 11.4351 1.32129 11.6701C1.32181 11.9052 1.38447 12.136 1.50292 12.339C1.62136 12.5421 1.79138 12.7102 1.99575 12.8264C2.20011 12.9425 2.43156 13.0026 2.66662 13.0005H13.3333C13.5672 13.0002 13.797 12.9385 13.9995 12.8213C14.202 12.7042 14.3701 12.5359 14.487 12.3332C14.6038 12.1306 14.6653 11.9007 14.6653 11.6668C14.6652 11.4329 14.6036 11.2031 14.4866 11.0005Z"
        stroke="#D52B50"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PingGeocodedIcon = ({ size = 24 }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="#dcf1e3ff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00016 8.00065L7.3335 9.33399L10.0002 6.66732M14.6668 8.00065C14.6668 11.6826 11.6821 14.6673 8.00016 14.6673C4.31826 14.6673 1.3335 11.6826 1.3335 8.00065C1.3335 4.31875 4.31826 1.33398 8.00016 1.33398C11.6821 1.33398 14.6668 4.31875 14.6668 8.00065Z"
        stroke="#347F5E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
