import { useEffect, useMemo, useState, useRef } from "react";

import { Panel, PanelGroup } from "react-resizable-panels";
import { useMeasure, useWindowSize } from "@uidotdev/usehooks";
import {
  BaseLayout,
  MainContent,
  PingPanelResizeHandle,
} from "@repo/ping-react-js";

import { PVPanel } from "features/submission-dashboard/PVPanel";
import { PingVisionSubmissionList } from "features/submission-dashboard/PingVisionSubmissionList";
import { PVSubmissionDetails } from "src/features/submission-dashboard/PVSubmissionDetails";
import { PingVisionSidebar } from "features/submission-dashboard/PingVisionSidebar";
import { TeamMembersManagement } from "features/submission-dashboard/TeamMembersManagement";
import CustomViewManagementPage from "features/custom-views/CustomViewManagementPage";
import { PVAllDocumentsPreviewPanel } from "features/submission-dashboard/PVAllDocumentsPreviewPanel";
import { PingCommandMenuModal } from "features/submission-dashboard/PingCommandMenu";
import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import { PVRibbon } from "src/features/submission-dashboard/PVRibbon";
import { useGetSubmissionHistoryList } from "features/submission-dashboard/queries";
import { useGetEnvironmentQuery, useGetSettingsQuery } from "services/pvSlice";
import { useAppSelector, useAppDispatch } from "utils/redux";
import { useSlug, usePingId, useTeamId } from "utils/hooks";
import { usePanelSizes } from "features/submission-dashboard/usePanelSizes";
import { setSubmissionHistory } from "reducers/inbox";
import { setIsCommandMenuOpened } from "reducers/settings";
import { setSelectedSovItem } from "services/selectedSovSlice";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";
import { APP_MODES } from "constants/SubmissionConstants";
import { PanelContext } from "utils/context";
import {
  PreviewContextProvider,
  usePreviewContext,
} from "./PVDocumentsPreviewContext";
import { DashboardPanel } from "features/submission-dashboard/panel-types";
import { useGetNav } from "features/submission-dashboard/queries";

import "./PingVisionSubmissionDashboard.scss";
import { useSubmissionsQueryGlobal } from "src/contexts/SubmissionsQueryGlobalContext";

const SUPPRESS_LEFT_NAV_SCREEN_WIDTH_PX = 1000; // Hide sidebar below this
const SUPPRESS_SUBMISSION_LIST_SCREEN_WIDTH_PX = 750; // Hide list below this

const PingVisionSubmissionDashboardContent = () => {
  const pingId = usePingId();
  const [isLeftNavVisible, setIsLeftNavVisible] = useState(true);
  const [isSubmissionListVisible, setIsSubmissionListVisible] = useState(true);
  const {
    isCollapsed: isPreviewPanelCollapsed,
    setIsCollapsed: setIsPreviewPanelCollapsed,
    setActiveType: setPreviewPanelActiveType,
  } = usePreviewContext();

  const slug = useSlug();

  const { globalSubmissionsData } = useSubmissionsQueryGlobal();

  const selectedSovItem = useAppSelector(
    (state) => state.selectedSov.selectedSovItem,
  );

  const { mode } = usePingVisionUrlStore();

  const detailMode = mode === APP_MODES.DETAIL;

  const isCommandMenuOpened = useAppSelector(
    (state) => state.settings.isCommandMenuOpened,
  );

  const dispatch = useAppDispatch();

  // Fetch the submission list
  const { data: submissionList } = globalSubmissionsData;

  // First try to find the item in the current sovs list
  const currentSelectedItem = useMemo(
    () => submissionList?.results?.find((sov) => sov.id === pingId),
    [submissionList?.results, pingId],
  );

  // If not found in current list but we have a pingId, use the globally stored selectedSovItem
  const selectedItem = useMemo(
    () => currentSelectedItem || (pingId ? selectedSovItem : null),
    [currentSelectedItem, selectedSovItem, pingId],
  );

  // Update selectedSovItem when a new item is selected
  useEffect(() => {
    if (currentSelectedItem) {
      dispatch(setSelectedSovItem(currentSelectedItem));
    }
  }, [currentSelectedItem, dispatch]);

  const [localSelectedItem, setLocalSelectedItem] = useState(selectedItem);

  useEffect(() => {
    setLocalSelectedItem(selectedItem);
  }, [selectedItem]);

  const previousSelectedItemId = useRef<string | null>(null);

  // when the submission changes, always open the preview panel again.
  // this works better when called outside of the preview panel.
  useEffect(() => {
    if (
      selectedItem?.id &&
      selectedItem.id !== previousSelectedItemId.current
    ) {
      setPreviewPanelActiveType(null); // we will let the preview panel handle setting active type when it loads
      previousSelectedItemId.current = selectedItem.id;
      setIsPreviewPanelCollapsed(false);
    }
  }, [selectedItem, setIsPreviewPanelCollapsed, setPreviewPanelActiveType]);

  useGetSettingsQuery({});

  useGetNav();

  useGetEnvironmentQuery();

  const { data: sovHistoryData = [] } = useGetSubmissionHistoryList();

  useEffect(() => {
    dispatch(setSubmissionHistory(sovHistoryData));
  }, [dispatch, sovHistoryData]);

  const windowSize = useWindowSize();

  // Check if we're in team management view based on URL path
  const isTeamManagementView =
    window.location.pathname.endsWith("/team-members");

  const isCustomViewsManagementView =
    window.location.pathname.endsWith("/custom-views");

  useEffect(() => {
    if (!windowSize.width) return;

    // Show/hide left nav based on window size
    if (windowSize.width <= SUPPRESS_LEFT_NAV_SCREEN_WIDTH_PX) {
      setIsLeftNavVisible(false);
    } else if (windowSize.width > SUPPRESS_LEFT_NAV_SCREEN_WIDTH_PX) {
      setIsLeftNavVisible(true);
    }

    // Show/hide submission list based on window size
    if (windowSize.width <= SUPPRESS_SUBMISSION_LIST_SCREEN_WIDTH_PX) {
      setIsSubmissionListVisible(false);
    } else {
      setIsSubmissionListVisible(true);
    }
  }, [windowSize]);

  // To calculate panel sizes in percentages, we need the width of the left
  // ribbon.
  const [ribbonRef, { width: ribbonWidth }] = useMeasure();

  // Calculate panel sizes in percentages, based on a set of minimum and maximum
  // pixel sizes.
  const { getMinPanelSize, getMaxPanelSize } = usePanelSizes(
    windowSize.width,
    ribbonWidth,
  );

  return (
    <PanelContext.Provider
      value={{
        isLeftNavVisible,
        isSubmissionListVisible,
      }}
    >
      <PingCommandMenuModal
        isOpen={isCommandMenuOpened}
        onClose={() => {
          dispatch(setIsCommandMenuOpened(false));
        }}
      />

      <BaseLayout
        title="Ping Radar [beta]"
        shouldShowPoweredByPing={false}
        className="PingVisionSubmissionDashboard"
      >
        <MainContent
          hasTopPadding={false}
          paddingSize="slim"
          className="PingVisionSubmissionDashboard__Main"
        >
          {!detailMode && (
            <PVRibbon
              onClickToggle={() => setIsLeftNavVisible(!isLeftNavVisible)}
              hasBorder={isLeftNavVisible}
              ref={ribbonRef}
            />
          )}
          {isCustomViewsManagementView ? (
            <PanelGroup direction="horizontal">
              <Panel
                id={DashboardPanel.SIDEBAR}
                order={1}
                minSize={
                  isLeftNavVisible ? getMinPanelSize(DashboardPanel.SIDEBAR) : 0
                }
                maxSize={
                  isLeftNavVisible ? getMaxPanelSize(DashboardPanel.SIDEBAR) : 0
                }
              >
                <PVPanel background="none">
                  <PingVisionSidebar />
                </PVPanel>
              </Panel>

              <PingPanelResizeHandle orientation="horizontal" width="slim" />

              <Panel
                id={DashboardPanel.TEAM_MEMBER_FORM}
                order={2}
                minSize={getMinPanelSize(DashboardPanel.TEAM_MEMBER_FORM)}
                maxSize={getMaxPanelSize(DashboardPanel.TEAM_MEMBER_FORM)}
              >
                <PVPanel>
                  <CustomViewManagementPage />
                </PVPanel>
              </Panel>
            </PanelGroup>
          ) : isTeamManagementView ? (
            <PanelGroup direction="horizontal">
              <Panel
                id={DashboardPanel.SIDEBAR}
                order={1}
                minSize={
                  isLeftNavVisible ? getMinPanelSize(DashboardPanel.SIDEBAR) : 0
                }
                maxSize={
                  isLeftNavVisible ? getMaxPanelSize(DashboardPanel.SIDEBAR) : 0
                }
              >
                <PVPanel background="none">
                  <PingVisionSidebar />
                </PVPanel>
              </Panel>

              <PingPanelResizeHandle orientation="horizontal" width="slim" />

              <Panel
                id={DashboardPanel.TEAM_MEMBER_FORM}
                order={2}
                minSize={getMinPanelSize(DashboardPanel.TEAM_MEMBER_FORM)}
                maxSize={getMaxPanelSize(DashboardPanel.TEAM_MEMBER_FORM)}
              >
                <PVPanel>
                  <TeamMembersManagement />
                </PVPanel>
              </Panel>
            </PanelGroup>
          ) : (
            <PanelGroup direction="horizontal">
              <Panel
                id={DashboardPanel.SIDEBAR}
                order={1}
                minSize={
                  isLeftNavVisible && !detailMode
                    ? getMinPanelSize(DashboardPanel.SIDEBAR)
                    : 0
                }
                maxSize={
                  isLeftNavVisible && !detailMode
                    ? getMaxPanelSize(DashboardPanel.SIDEBAR)
                    : 0
                }
              >
                {isLeftNavVisible && !detailMode && (
                  <PVPanel background="none">
                    <PingVisionSidebar />
                  </PVPanel>
                )}
              </Panel>

              {isLeftNavVisible && !detailMode && (
                <PingPanelResizeHandle orientation="horizontal" width="slim" />
              )}

              <Panel
                id={DashboardPanel.SUBMISSION_LIST}
                order={2}
                minSize={
                  slug && !detailMode && isSubmissionListVisible
                    ? getMinPanelSize(DashboardPanel.SUBMISSION_LIST)
                    : 0
                }
                maxSize={
                  slug && !detailMode && isSubmissionListVisible
                    ? getMaxPanelSize(DashboardPanel.SUBMISSION_LIST)
                    : 0
                }
              >
                <PVPanel>
                  <PingVisionSubmissionList />
                </PVPanel>
              </Panel>

              {slug && !detailMode && isSubmissionListVisible && (
                <PingPanelResizeHandle orientation="horizontal" width="slim" />
              )}

              <Panel
                id={DashboardPanel.SUBMISSION_DETAILS}
                order={3}
                minSize={getMinPanelSize(DashboardPanel.SUBMISSION_DETAILS)}
                maxSize={getMaxPanelSize(DashboardPanel.SUBMISSION_DETAILS)}
              >
                <PanelGroup direction="horizontal">
                  <Panel order={1} minSize={30} maxSize={70} defaultSize={60}>
                    {localSelectedItem ? (
                      <PVPanel>
                        <PVSubmissionDetails selectedItem={localSelectedItem} />
                      </PVPanel>
                    ) : (
                      <PVPanel>
                        <PVEmptyPanelMessage message="Select a submission to view details" />
                      </PVPanel>
                    )}
                  </Panel>

                  {selectedItem && !isPreviewPanelCollapsed && (
                    <PingPanelResizeHandle
                      orientation="horizontal"
                      width="slim"
                    />
                  )}

                  <Panel
                    key={`${isPreviewPanelCollapsed?.toString()}-${selectedItem?.id?.toString()}`}
                    order={2}
                    minSize={selectedItem && !isPreviewPanelCollapsed ? 30 : 0}
                    maxSize={selectedItem && !isPreviewPanelCollapsed ? 70 : 0}
                  >
                    {selectedItem && !isPreviewPanelCollapsed ? (
                      <PVPanel>
                        <PVAllDocumentsPreviewPanel
                          selectedItem={selectedItem}
                        />
                      </PVPanel>
                    ) : null}
                  </Panel>
                </PanelGroup>
              </Panel>
            </PanelGroup>
          )}
        </MainContent>
      </BaseLayout>
    </PanelContext.Provider>
  );
};

const PingVisionSubmissionDashboard = () => {
  return (
    <PreviewContextProvider>
      <PingVisionSubmissionDashboardContent />
    </PreviewContextProvider>
  );
};

export default PingVisionSubmissionDashboard;
