import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EnvType } from "ts-types/DataTypes";
import { getApiBaseUrl } from "../utils";
import { api } from "services/pvSlice";
import slugify from "slugify";
import type {
  NavigationResponse,
  SettingsResponseType,
} from "ts-types/ApiTypes";
import type { UserType } from "ts-types/DataTypes";

type QueryParamsType = Record<
  string,
  string | Record<string, number> | ((id: number) => Record<string, number>)
>;

export const getAllInboxCounts = (input) => {
  const inboxCounts = [];

  const extractInboxCount = (item) => {
    // Check if this item has a slug of "inbox"
    if (item.slug === "inbox") {
      let count = "0";

      if (item.count) {
        count = item.count;
      }

      inboxCounts.push({
        team_name: item.team_name || "Unknown Team",
        team_id: item.team_id,
        slug: item.slug,
        inbox_count: count,
      });
    }

    if (item.items && Array.isArray(item.items)) {
      item.items.forEach((subItem) => extractInboxCount(subItem));
    }
  };

  if (input.views && Array.isArray(input.views)) {
    input.views.forEach((view) => extractInboxCount(view));
  }

  return inboxCounts;
};

export const getMyIssuesCount = (input) => {
  let myIssuesCount = "0";

  const findMyIssuesCount = (item) => {
    if (item.slug === "my-issues" && item.count) {
      myIssuesCount = item.count;
      return true;
    }
    return false;
  };

  if (input.views && Array.isArray(input.views)) {
    for (const view of input.views) {
      if (findMyIssuesCount(view)) {
        break; // Found it, no need to continue searching
      }
    }
  }

  return myIssuesCount;
};

const transformJson = (input) => {
  const result: QueryParamsType = {};

  const getInboxCount = () => {
    return getAllInboxCounts(input);
  };

  const getMyIssuesCountValue = () => {
    return getMyIssuesCount(input);
  };

  const processItem = (item, parentName = "") => {
    if (item.slug && item.filter) {
      const key = parentName
        ? `${slugify(parentName)}-${item.slug}`
        : item.slug;

      // Create the result object
      const resultObj: any = { filter: item.filter };

      // Add sortConfig if order_by is present
      if (item.order_by) {
        const [field, direction] = item.order_by.split(":");
        resultObj.sortConfig = {
          field: field as "inception_date" | "received_time",
          direction: direction as "asc" | "desc",
        };
      }

      // temp hack till we fix my-issues on the server

      if (item.slug === "my-issues") {
        resultObj.sortConfig = {
          field: "received_time",
          direction: "asc",
        };
      }

      result[key] = resultObj;
    }

    if (item.items && Array.isArray(item.items)) {
      const name = item.name || parentName;
      item.items.forEach((subItem) => processItem(subItem, name));
      result[`${slugify(name)}-my-issues`] = (claimed_by_id: number) => {
        return { claimed_by_id };
      };
    }
  };

  input.views.forEach((item) => processItem(item));

  return {
    navToQueryParams: result,
    inboxCounts: getInboxCount(),
    myIssuesCount: getMyIssuesCountValue(),
  };
};

// Define the SortConfig type for reuse
export type SortConfig = {
  field: "inception_date" | "received_time";
  direction: "asc" | "desc";
};

type PingSettingsInitialState = {
  /* */
  baseUrl: string;
  /* */
  env: string;
  /* */
  isPingEmployee: boolean;
  /* */
  envData: EnvType | null;
  /* */
  nav: NavigationResponse | null;
  /* */
  settings: SettingsResponseType | null;
  /* */
  isCommandMenuOpened: boolean;
  /* */
  selectedSubmissions: string[];
  /**/
  userIdToUserMap: Record<number, UserType>;
  /* */
  navToQueryParams: QueryParamsType;
  /* what to display with the submission dashboard */
  currentPanelContent: {
    type: "submission" | "team-management";
    teamId?: string;
  } | null;
  inboxCounts: {
    team_name: string;
    team_id: number;
    slug: string;
    inbox_count: string;
  }[];
  /* Count of issues assigned to the current user across all teams */
  myIssuesCount: string;
  /* Indicates if there are more submissions available to load */
  hasRemainingSovs: boolean;
  /* Global sort configuration for submissions */
  globalSortConfig: SortConfig | null;
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    baseUrl: getApiBaseUrl(),
    env: import.meta.env.VITE_APP_ENV,
    isPingEmployee: false,
    envData: null,
    nav: null,
    settings: null,
    isCommandMenuOpened: false,
    selectedSubmissions: [],
    userIdToUserMap: {},
    navToQueryParams: {},
    currentPanelContent: null,
    inboxCounts: [],
    myIssuesCount: "0",
    globalSortConfig: { direction: "asc", field: "received_time" },
    hasRemainingSovs: false,
  } as PingSettingsInitialState,
  reducers: {
    setIsEmployee(state, actions: PayloadAction<boolean>) {
      state.isPingEmployee = actions.payload;
    },
    setIsCommandMenuOpened(state, actions: PayloadAction<boolean>) {
      state.isCommandMenuOpened = actions.payload;
    },
    setSelectedSubmissions(state, actions: PayloadAction<string[]>) {
      state.selectedSubmissions = actions.payload;
    },
    setCurrentPanelContent(
      state,
      action: PayloadAction<PingSettingsInitialState["currentPanelContent"]>,
    ) {
      state.currentPanelContent = action.payload;
    },

    updateInboxCount(
      state,
      action: PayloadAction<{
        teamId: number;
        delta?: number;
        newCount?: string;
      }>,
    ) {
      const { teamId, delta, newCount } = action.payload;

      const teamIndex = state.inboxCounts.findIndex(
        (item) => item.team_id === teamId,
      );

      if (teamIndex === -1) {
        return;
      }

      // If newCount is provided, directly set the inbox count
      if (newCount) {
        state.inboxCounts[teamIndex].inbox_count = newCount;
        return;
      }

      // Handle delta (incremental) case
      if (delta) {
        const currentCount = state.inboxCounts[teamIndex].inbox_count;

        if (currentCount === "100+") {
          return;
        }

        const parsedCount = parseInt(currentCount);
        const newCount = parsedCount + delta;

        if (newCount >= 100) {
          state.inboxCounts[teamIndex].inbox_count = "100+";
        } else {
          state.inboxCounts[teamIndex].inbox_count = newCount.toString();
        }
      }
    },
    updateMyIssuesCount(
      state,
      action: PayloadAction<{
        delta?: number;
        newCount?: string;
      }>,
    ) {
      const { delta, newCount } = action.payload;

      if (newCount) {
        state.myIssuesCount = newCount;
        return;
      }

      if (delta) {
        const currentCount = state.myIssuesCount;

        if (currentCount === "100+") {
          return;
        }

        const parsedCount = parseInt(currentCount);
        const updatedCount = parsedCount + delta;

        if (updatedCount >= 100) {
          state.myIssuesCount = "100+";
        } else {
          state.myIssuesCount = updatedCount.toString();
        }
      }
    },

    // Set the global sort configuration
    setGlobalSortConfig(state, action: PayloadAction<SortConfig | null>) {
      state.globalSortConfig = action.payload;
    },

    // Update nav and navToQueryParams when receiving WebSocket updates
    updateNavAndQueryParams(
      state,
      action: PayloadAction<{ nav: NavigationResponse }>,
    ) {
      if (action.payload.nav) {
        processNavAndUpdateState(state, action.payload.nav);
      }
    },
  },
  extraReducers: (builder) => {
    // All addMatcher calls must come after all addCase calls
    builder.addMatcher(
      api.endpoints.getSettings.matchFulfilled,
      (state, action: PayloadAction<SettingsResponseType>) => {
        state.settings = action.payload;
        state.userIdToUserMap = action.payload.users?.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
      },
    );
    builder.addMatcher(
      api.endpoints.getNav.matchFulfilled,
      (state, action: PayloadAction<NavigationResponse>) => {
        // Create a deep copy of action.payload to avoid mutating the original
        const processedPayload = JSON.parse(JSON.stringify(action.payload));
        processNavAndUpdateState(state, processedPayload);
      },
    );

    builder.addMatcher(
      api.endpoints.getEnvironment.matchFulfilled,
      (state, action: PayloadAction<EnvType>) => {
        state.envData = action.payload;
      },
    );

    // Set hasRemainingSovs based on the getSubmissions response
    builder.addMatcher(
      api.endpoints.getSubmissions.matchFulfilled,
      (state, action) => {
        // If has_remaining is false, set hasRemainingSovs to false
        if (!action.payload?.has_remaining) {
          state.hasRemainingSovs = false;
        } else {
          state.hasRemainingSovs = true;
        }
      },
    );
  },
});

/**
 * Helper function to process navigation data and update state
 * @param state - The current state object
 * @param navData - The navigation data to process
 */
const processNavAndUpdateState = (state: any, navData: NavigationResponse) => {
  // Process each item in navData to modify slugs for USER_CUSTOM view types
  const processViews = (payload: NavigationResponse) => {
    if (!Array.isArray(payload.views)) return payload;

    const newViews = payload.views.map((item) => {
      if (item.view_type === "USER_CUSTOM" && item.slug) {
        return {
          ...item,
          slug: `custom-views/custom-view-${item.slug}`,
          originalCustomViewSlug: item.slug,
        };
      }
      return item;
    });
    return { ...payload, views: newViews };
  };

  // Process the nav object
  const processedNav = processViews(navData);

  // Update the nav state
  state.nav = processedNav;

  // Transform the nav data to update navToQueryParams and other related state
  const result = transformJson(processedNav);
  state.navToQueryParams = result.navToQueryParams;
  state.inboxCounts = result.inboxCounts;
  state.myIssuesCount = result.myIssuesCount;
};

const { actions, reducer } = settingsSlice;

export const {
  setSelectedSubmissions,
  setIsCommandMenuOpened,
  setIsEmployee,
  setCurrentPanelContent,
  updateInboxCount,
  updateMyIssuesCount,
  updateNavAndQueryParams,
  setGlobalSortConfig,
} = actions;

export default reducer;
