import cx from "classnames";

import "./Button.scss";
import * as LucideIcons from "lucide-react";
import { Icon } from "../Icon";
import { ComponentType, forwardRef, isValidElement, ReactElement } from "react";

type ButtonProps = {
  label?: string;
  variant?: "solid" | "ghost" | "destructive" | "outline" | "brand" | "inverse";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseOver?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseOut?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "submit";
  size?: "lg" | "md" | "sm" | "xs";
  iconName?: keyof typeof LucideIcons | ReactElement | ComponentType<any>;
  iconPosition?: "left" | "right";
  iconProps?: Record<string, any>;
  style?: React.CSSProperties;
  className?: string;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      variant = "solid",
      onClick,
      onMouseOver,
      onMouseOut,
      type = "button",
      size = "md",
      iconName,
      iconPosition = "left",
      iconProps = {},
      style,
      className,
    },
    ref,
  ) => {
    const appliedClasses = cx(
      "Button",
      {
        // Size
        "Button--Large": size === "lg" && label,
        "Button--Medium": size === "md" && label,
        "Button--Small": size === "sm" && label,
        "Button--XSmall": size === "xs" && label,
        "Button--IconLarge": size === "lg" && !label,
        "Button--IconMedium": size === "md" && !label,
        "Button--IconSmall": size === "sm" && !label,
        "Button--IconXSmall": size === "xs" && !label,

        // Variant
        "Button--Solid": variant === "solid",
        "Button--Ghost": variant === "ghost",
        "Button--Destructive": variant === "destructive",
        "Button--Outline": variant === "outline",
        "Button--Brand": variant === "brand",
        "Button--Inverse": variant === "inverse" && label,
        "Button--InverseIcon": variant === "inverse" && !label,
      },
      className,
    );

    const renderIcon = () => {
      if (!iconName) return null;

      if (typeof iconName === "string") {
        return (
          <Icon
            iconName={iconName as keyof typeof LucideIcons}
            {...iconProps}
          />
        );
      }

      if (isValidElement(iconName)) {
        return iconName;
      }

      const IconComponent = iconName as ComponentType<any>;
      return <IconComponent {...iconProps} />;
    };

    return (
      <button
        ref={ref}
        className={appliedClasses}
        onClick={onClick}
        type={type}
        style={style}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        {iconName && iconPosition === "left" && (
          <div className={"Button__Icon"}>{renderIcon()}</div>
        )}
        {label && <div className="Button__Label">{label}</div>}
        {iconName && iconPosition === "right" && (
          <div className={"Button__Icon"}>{renderIcon()}</div>
        )}
      </button>
    );
  },
);

export { Button };
