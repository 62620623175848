import { PING_VISION_DEFAULT_GRID_PAGE_SIZE } from "constants/ApiConstants";
import { useCallback, useEffect, useMemo, useState, useRef, memo } from "react";
import { useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { useMeasure } from "@uidotdev/usehooks";
import {
  PingLucideIcon,
  PingPopover,
  PingPopoverContent,
  PingPopoverTrigger,
} from "@repo/ping-react-js";
import {
  PingSearchQueryBuilder,
  useSearchQueryBuilder,
} from "@repo/ping-react-js";
import {
  SEARCH_PARAM_NAME,
  useGetAdvancedSearchFields,
} from "features/submission-dashboard/queries";

import {
  LoadingMessagePanel,
  PingVirtualWindow,
  PingButton,
  areObjectsEqual,
} from "@repo/ping-react-js";

import { PingVisionSubmissionListItem } from "features/submission-dashboard/PingVisionSubmissionListItem";
import {
  updateMultiSelectToast,
  clearMultiSelectToast,
} from "features/submission-dashboard/PingMultiSelectOptions";
import { useAppDispatch, useAppSelector } from "utils/redux";
import { usePingId, useSlug, useTeamId } from "utils/hooks";
import {
  setIsCommandMenuOpened,
  setSelectedSubmissions,
} from "reducers/settings";
import { addFilteredSubmissionIds } from "services/filteredSubmissionIdsSlice";
import { setCurrentCursorId, setSlug, setTeamId } from "reducers/inbox";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";
import { APP_MODES } from "constants/SubmissionConstants";
import { SovDataType } from "ts-types/DataTypes";
import { useSubmissionsQueryGlobal } from "../../contexts/SubmissionsQueryGlobalContext.tsx";

import "./PingVisionSubmissionList.scss";

export const PingVisionSubmissionList = memo(() => {
  const dispatch = useAppDispatch();
  const { setMode } = usePingVisionUrlStore();

  const selectedItemId = usePingId();
  const teamId = useTeamId();
  const {
    handleSearchStateChange,
    handleSortChange,
    hasAssigneeMismatch,
    hasWorkflowMismatch,
    globalSubmissionsData,
  } = useSubmissionsQueryGlobal();

  const history = useHistory();

  const sortConfig = useAppSelector((state) => state.settings.globalSortConfig);

  const matchingUserId = useAppSelector(
    (state) => state.settings?.envData?.user?.id,
  );
  const selectedSubmissions = useAppSelector(
    (state) => state.settings.selectedSubmissions,
  );
  const inboxSlug = useAppSelector((state) => state.inbox.slug);

  const workflowUpdates = useAppSelector(
    (state) => state.workflowUpdates.recentUpdates,
  );

  const claimantUpdates = useAppSelector(
    (state) => state.claimantUpdates.recentUpdates,
  );

  const { advancedSearchFields: fields } = useGetAdvancedSearchFields();

  // Get search values from URL query params for UI display and interaction
  // Note: We use a local instance rather than the global context version
  // because these values are specifically for UI state management in this component
  // and shouldn't trigger list filtering or new submission queries directly
  const { searchValues } = useSearchQueryBuilder(
    fields,
    history,
    SEARCH_PARAM_NAME,
  );

  const filteredSubmissionIds = useAppSelector(
    (state) => state.filteredSubmissionIds.filteredIds,
  );

  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [resetScroll, setResetScroll] = useState(false);

  const { data: sovData, isLoading: isFetchingSovData } = globalSubmissionsData;

  const hasRemaining = sovData?.has_remaining;
  const nextCursorId = sovData?.cursor_id;
  const totalResultsCount = sovData?.total_size_without_cursors;

  /* These functions determine if submissions should be kept or filtered from the list:
   * - filterSubmission: Main filtering function that applies all filter criteria
   * - hasClientAssigneeUpdate: Checks if current user made assignee changes
   * - hasClientStatusUpdate: Checks if current user made status changes
   * - hasRemovableAssigneeUpdate: Determines if assignee changes should filter out the item
   * - hasRemovableWorkflowUpdate: Determines if status changes should filter out the item
   * - checkAssigneeMismatch: Validates if assignee no longer matches filter criteria
   * - checkWorkflowMismatch: Validates if workflow status no longer matches filter criteria
   */

  const hasClientStatusUpdate = useCallback(
    (submission: SovDataType) => {
      return workflowUpdates.some(
        (update) =>
          update.submissionId === submission.id &&
          update.userId === matchingUserId,
      );
    },
    [workflowUpdates, matchingUserId],
  );

  const hasClientAssigneeUpdate = useCallback(
    (submission: SovDataType) => {
      const saveVar = claimantUpdates.some(
        (update) =>
          update.submissionId === submission.id &&
          update.userId === matchingUserId,
      );

      return saveVar;
    },
    [claimantUpdates, matchingUserId],
  );

  const checkWorkflowMismatch = useCallback(
    (submission: SovDataType): boolean => {
      return hasWorkflowMismatch(submission.workflow_status_id);
    },
    [hasWorkflowMismatch],
  );

  const checkAssigneeMismatch = useCallback(
    (submission: SovDataType): boolean => {
      return hasAssigneeMismatch(submission.claimed_by_id);
    },
    [hasAssigneeMismatch],
  );

  const hasRemovableWorkflowUpdate = useCallback(
    (item: SovDataType) => {
      const hasStatusMismatch = checkWorkflowMismatch(item);

      const selectedItemCheck =
        item.id !== selectedItemId || hasClientStatusUpdate(item);

      return hasStatusMismatch && selectedItemCheck;
    },
    [selectedItemId, checkWorkflowMismatch, hasClientStatusUpdate],
  );

  const hasRemovableAssigneeUpdate = useCallback(
    (item: SovDataType) => {
      const hasAssigneeMismatch = checkAssigneeMismatch(item);

      const selectedItemCheck =
        item.id !== selectedItemId || hasClientAssigneeUpdate(item);
      return hasAssigneeMismatch && selectedItemCheck;
    },
    [
      checkAssigneeMismatch,
      selectedItemId,
      hasClientAssigneeUpdate,
      claimantUpdates,
    ],
  );

  const filteredSovs = useMemo(() => {
    if (isFetchingSovData) {
      return [];
    }

    const filterSubmission = (submission: SovDataType) => {
      return (
        !hasRemovableWorkflowUpdate(submission) &&
        !hasRemovableAssigneeUpdate(submission)
      );
    };

    const filteredResults = (sovData?.results || []).filter(filterSubmission);

    return filteredResults;
  }, [
    sovData,
    hasRemovableWorkflowUpdate,
    hasRemovableAssigneeUpdate,
    isFetchingSovData,
  ]);

  useEffect(() => {
    // Track filtered-out submission IDs to maintain accurate list counts
    // When submissions no longer meet filter criteria (due to status/assignee changes),
    // they're removed from the visible list. This effect triggers additional data fetching
    // to maintain the expected number of items in the list view, even when some are filtered out.
    if (sovData?.results) {
      const originalIds = sovData.results.map((submission) => submission.id);
      const filteredIds = filteredSovs.map((submission) => submission.id);
      const filteredOutIds = originalIds.filter(
        (id) => !filteredIds.includes(id),
      );

      // Add filtered out IDs to the global state with the current slug
      if (filteredOutIds.length > 0 && inboxSlug) {
        dispatch(
          addFilteredSubmissionIds({ slug: inboxSlug, ids: filteredOutIds }),
        );
      }
    }
  }, [sovData, filteredSovs, dispatch, inboxSlug, isFetchingSovData]);

  const filteredResultsDiff = useMemo(() => {
    if (!sovData?.results || !inboxSlug) return 0;

    let saveFilteredResultsDiff = 0;

    // Get the filtered submission IDs for the current slug
    const currentSlugFilteredIds = filteredSubmissionIds[inboxSlug] || [];

    for (const id of currentSlugFilteredIds) {
      if (!sovData.results.find((s) => s.id === id)) {
        saveFilteredResultsDiff++;
      }
    }

    return saveFilteredResultsDiff;
  }, [sovData?.results, filteredSubmissionIds, inboxSlug]);

  const adjustedResultsCount = useMemo(() => {
    if (!totalResultsCount) return "0";

    if (!hasRemaining) {
      return filteredSovs.length >= 100
        ? "100+"
        : filteredSovs.length.toString();
    }

    if (totalResultsCount === "100+") return totalResultsCount;

    const count = parseInt(totalResultsCount, 10);
    if (isNaN(count)) return totalResultsCount;

    return filteredResultsDiff > 0
      ? (count - filteredResultsDiff).toString()
      : totalResultsCount;
  }, [
    totalResultsCount,
    filteredResultsDiff,
    hasRemaining,
    filteredSovs.length,
  ]);

  const [isShiftDown, setIsShiftDown] = useState<boolean>(false);
  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);

  const slug = useSlug();

  const prevSelectedItemsSize = useRef(0);

  useEffect(() => {
    if (
      !selectedSubmissions?.length &&
      selectedItems.size &&
      prevSelectedItemsSize.current > 0
    ) {
      setSelectedItems(new Set());
    }
    prevSelectedItemsSize.current = selectedItems.size;
  }, [selectedSubmissions, selectedItems]);

  useEffect(() => {
    if (slug) {
      dispatch(setSlug(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (teamId) {
      dispatch(setTeamId(teamId));
    }
  }, [dispatch, teamId]);

  /**
   * Ensures minimum page size is maintained when client-side filtering reduces visible items.
   *
   * When submissions no longer meet filter criteria (due to status/assignee changes),
   * they're removed from the visible list. This effect triggers additional data fetching
   * to maintain the expected number of items in the list view.
   */
  useEffect(() => {
    if (
      filteredSovs.length < PING_VISION_DEFAULT_GRID_PAGE_SIZE &&
      hasRemaining &&
      !isFetchingSovData &&
      nextCursorId
    ) {
      dispatch(setCurrentCursorId(nextCursorId));
    }
  }, [
    dispatch,
    filteredSovs.length,
    hasRemaining,
    isFetchingSovData,
    nextCursorId,
  ]);

  /* This is meant to handle the selected items state and update the command menu */
  useEffect(() => {
    if (selectedItems.size) {
      const selectedItemsArray = [...selectedItems];

      // Ensure we only keep selected items that are currently visible in the filtered list
      // This prevents a bug where items remain selected after being filtered out of view

      const filteredSovIds = filteredSovs.map((s) => s.id);
      const filteredSelectedIds = selectedItemsArray.filter((id) =>
        filteredSovIds.includes(id),
      );

      dispatch(setSelectedSubmissions(filteredSelectedIds));
      updateMultiSelectToast(
        new Set(filteredSelectedIds),
        () => {
          setSelectedItems(new Set());
        },
        () => {
          dispatch(setIsCommandMenuOpened(true));
        },
        () => {},
      );
    } else {
      clearMultiSelectToast();
      dispatch(setSelectedSubmissions([]));
    }
  }, [selectedItems, dispatch, filteredSovs]);

  /* This is meant to handle the checkbox selection to select items  */
  const handleCheckboxChange = useCallback(
    (id: string, checked: boolean) => {
      setSelectedItems((prevSelected) => {
        const newSelected = new Set(prevSelected);
        const results = sovData?.results || [];

        if (isShiftDown && lastSelectedItem && checked && results.length > 0) {
          const startIndex = results.findIndex(
            (sov) => sov?.id === lastSelectedItem,
          );
          const endIndex = results.findIndex((sov) => sov?.id === id);

          // Only proceed if we found both indices
          if (startIndex !== -1 && endIndex !== -1) {
            const [lower, upper] = [startIndex, endIndex].sort((a, b) => a - b);

            for (let i = lower; i <= upper; i++) {
              const item = results[i];
              if (item?.id) {
                newSelected.add(item.id);
              }
            }
          } else {
            // If indices not found, just add the current item
            newSelected.add(id);
          }
        } else {
          if (checked) {
            newSelected.add(id);
          } else {
            newSelected.delete(id);
          }
        }

        setLastSelectedItem(checked ? id : null);
        return newSelected;
      });
    },
    [isShiftDown, lastSelectedItem, sovData?.results],
  );

  const onClickIncomingItem = useCallback(
    (id: string) => {
      if (!teamId && !slug) {
        return;
      }
      if (isShiftDown) {
        handleCheckboxChange(id, !selectedItems.has(id));
        return;
      }

      const currentSearchParams = new URLSearchParams(window.location.search);
      currentSearchParams.set("selected", id);
      history.push(`${window.location.pathname}?${currentSearchParams}`);
    },
    [isShiftDown, teamId, history, slug, handleCheckboxChange, selectedItems],
  );

  /**
   * Handles double-click events on submission list items.
   * When a user double-clicks on an item, it switches to detail view mode
   * (hiding the list and showing only the navbar and submission details)
   * unless shift key is being held down.
   */
  const handleDoubleClickItem = useCallback(() => {
    if (isShiftDown) {
      return;
    }
    setMode(APP_MODES.DETAIL);
  }, [setMode, isShiftDown]);

  const currentIndex = sovData?.results?.findIndex(
    (s) => s.id === selectedItemId,
  );

  const selectNextSov = useCallback(() => {
    if (
      currentIndex !== undefined &&
      sovData?.results &&
      currentIndex < sovData.results.length - 1
    ) {
      const nextSov = sovData.results[currentIndex + 1];
      onClickIncomingItem(nextSov.id);
    }
  }, [currentIndex, sovData?.results, onClickIncomingItem]);

  const selectPreviousSov = useCallback(() => {
    if (currentIndex !== undefined && sovData?.results && currentIndex > 0) {
      const previousSov = sovData.results[currentIndex - 1];
      onClickIncomingItem(previousSov.id);
    }
  }, [currentIndex, sovData?.results, onClickIncomingItem]);

  useHotkeys("down", selectNextSov, [selectNextSov]);
  useHotkeys("up", selectPreviousSov, [selectPreviousSov]);
  useHotkeys("esc", () => {
    setSelectedItems(new Set());
    setLastSelectedItem(null);
  });

  useHotkeys(
    "shift",
    () => {
      setIsShiftDown(true);
    },
    { keyup: false, enableOnFormTags: ["input", "select", "textarea"] },
  );

  useHotkeys(
    "shift",
    () => {
      setIsShiftDown(false);
    },
    { keyup: true, enableOnFormTags: ["input", "select", "textarea"] },
  );

  const [submissionListRef, { height: submissionListHeight }] = useMeasure();

  const prevValuesRef = useRef({
    sortConfig,
    inboxSlug,
    teamId,
    searchValues,
  });

  useEffect(() => {
    /*
     * Reset scroll position to the top when filter, sort, or search criteria change
     * This ensures users see the beginning of the newly filtered/sorted list
     */

    const hasChanged =
      !areObjectsEqual(
        prevValuesRef.current.sortConfig || {},
        sortConfig || {},
      ) ||
      prevValuesRef.current.inboxSlug !== inboxSlug ||
      prevValuesRef.current.teamId !== teamId ||
      !areObjectsEqual(
        prevValuesRef.current.searchValues || {},
        searchValues || {},
      );

    if (hasChanged) {
      setResetScroll(true);

      // Update the ref with current values immediately
      prevValuesRef.current = {
        sortConfig,
        inboxSlug,
        teamId,
        searchValues,
      };
    }

    // Reset the flag after a short delay to allow future sort changes to trigger scroll reset
    const timer = setTimeout(() => {
      if (resetScroll) {
        setResetScroll(false);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [dispatch, sortConfig, inboxSlug, teamId, resetScroll, searchValues]);

  /**
   * Handles sorting when a sort option is clicked
   * @param field - The field to sort by (inception_date or received_time)
   * @param direction - Optional direction to sort in (asc or desc). If not provided, toggles current direction
   */
  const handleSortClick = useCallback(
    (field: "inception_date" | "received_time", direction?: "asc" | "desc") => {
      handleSortChange({
        field,
        direction,
      });
    },
    [handleSortChange],
  );

  return (
    <div className="PingVisionSubmissionList__Container">
      {!inboxSlug?.includes("custom-view") && (
        <div className="PingVisionSubmissionList__Filters">
          <div className="PingVisionSubmissionList__ResultsCount">
            {adjustedResultsCount}{" "}
            {adjustedResultsCount === "1" ? "result" : "results"}
          </div>
          <div className="PingVisionSubmissionList__SortBySection">
            <span className="PingVisionSubmissionList__SortByText">Sorted</span>
            <span className="PingVisionSubmissionList__SortValue">
              {sortConfig?.field === "received_time"
                ? "Time received"
                : "Inception date"}{" "}
              <PingLucideIcon
                iconName={
                  sortConfig?.direction === "asc" ? "ArrowUp" : "ArrowDown"
                }
                size={16}
              />
            </span>
          </div>
        </div>
      )}

      {!inboxSlug?.includes("custom-view") && (
        <div className="PingVisionSubmissionList__Search">
          <PingSearchQueryBuilder
            fields={fields}
            values={searchValues}
            onChange={handleSearchStateChange}
            advancedSearchButtonOutside
          />
          <PingPopover placement="bottom-end">
            <PingPopoverTrigger className="PingVisionSubmissionList__SortButton">
              <PingLucideIcon iconName="ArrowUpDown" size={20} />
            </PingPopoverTrigger>
            {/* nothing that follows is probably very accessible. onClick on divs? Need to examine that and the
            PingPopoverContent semantic markup at some point. tabIndex on the divs helps for tabbing right now, in the absence of buttons. */}
            <PingPopoverContent className="PingVisionSubmissionList__SortMenu">
              <div
                className={`PingVisionSubmissionList__SortMenuItem ${sortConfig?.field === "received_time" && sortConfig?.direction === "asc" ? "selected" : ""}`}
                onClick={() => handleSortClick("received_time", "asc")}
                tabIndex={0}
              >
                <span>Time received</span>
                <PingLucideIcon iconName="ArrowUp" />
              </div>
              <div
                className={`PingVisionSubmissionList__SortMenuItem ${sortConfig?.field === "received_time" && sortConfig?.direction === "desc" ? "selected" : ""}`}
                onClick={() => handleSortClick("received_time", "desc")}
                tabIndex={0}
              >
                <span>Time received</span>
                <PingLucideIcon iconName="ArrowDown" />
              </div>
              <div
                className={`PingVisionSubmissionList__SortMenuItem ${sortConfig?.field === "inception_date" && sortConfig?.direction === "asc" ? "selected" : ""}`}
                onClick={() => handleSortClick("inception_date", "asc")}
                tabIndex={0}
              >
                <span>Inception date</span>
                <PingLucideIcon iconName="ArrowUp" />
              </div>
              <div
                className={`PingVisionSubmissionList__SortMenuItem ${sortConfig?.field === "inception_date" && sortConfig?.direction === "desc" ? "selected" : ""}`}
                onClick={() => handleSortClick("inception_date", "desc")}
                tabIndex={0}
              >
                <span>Inception date</span>
                <PingLucideIcon iconName="ArrowDown" />
              </div>
            </PingPopoverContent>
          </PingPopover>
        </div>
      )}

      <ul className="PingVisionSubmissionList" ref={submissionListRef}>
        {submissionListHeight && (
          <PingVirtualWindow
            windowHeight={submissionListHeight}
            items={filteredSovs}
            resetScroll={resetScroll}
            renderItem={(item: unknown, index: number) => {
              const sovItem = item as SovDataType;
              const key = `sov-${sovItem.id}-${selectedItems.has(sovItem.id)}`;
              const statusMismatchCondition =
                !hasClientStatusUpdate(sovItem) &&
                checkWorkflowMismatch(sovItem);
              const assigneeMismatchCondition =
                !hasClientAssigneeUpdate(sovItem) &&
                checkAssigneeMismatch(sovItem);

              // Determines if an item should be visually highlighted (in red) because it no longer belongs
              // in the current view due to status or assignee changes, but is temporarily retained
              // because the user is currently viewing or interacting with it
              const itemMismatchCondition =
                statusMismatchCondition || assigneeMismatchCondition;

              const hasMismatch =
                !isFetchingSovData &&
                inboxSlug &&
                sovItem?.workflow_status_id &&
                itemMismatchCondition;

              return (
                <PingVisionSubmissionListItem
                  itemIsChecked={selectedItems.has(sovItem.id)}
                  key={key}
                  sov={sovItem}
                  isSelected={selectedItemId === sovItem.id}
                  onClickIncomingItem={onClickIncomingItem}
                  onCheckboxChange={handleCheckboxChange}
                  onDoubleClickItem={handleDoubleClickItem}
                  className={hasMismatch ? "workflow-status-mismatch" : ""}
                />
              );
            }}
            onEndReached={() => {
              if (nextCursorId && hasRemaining && !isFetchingSovData) {
                dispatch(setCurrentCursorId(nextCursorId));
              }

              return Promise.resolve();
            }}
          />
        )}
        {isFetchingSovData && <LoadingMessagePanel />}
      </ul>
    </div>
  );
});
