import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  useGetTeamMembersQuery,
  useCreateTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useUpdateTeamMemberMutation,
} from "services/pvSlice";
import {
  PingButton,
  PingDataGrid,
  PingSelectInput,
  PingTextInput,
  PingLucideIcon,
  PingTooltip,
  PingTooltipTrigger,
  PingTooltipContent,
  useAuth,
  PingPanelResizeHandle,
} from "@repo/ping-react-js";
import { Panel, PanelGroup } from "react-resizable-panels";
import { PVPanel } from "./PVPanel";
import { PingVisionModal } from "../../components/modals/PingVisionModal";
import toast from "react-hot-toast";
import "./TeamMembersManagement.scss";

type AddMemberFormData = {
  first_name: string;
  last_name: string;
  user_email: string;
  membership_type: string;
};

interface AddMemberSectionProps {
  onClose: () => void;
  onSubmit: (data: AddMemberFormData) => void;
}

const AddMemberSection: React.FC<AddMemberSectionProps> = React.memo(
  ({ onClose, onSubmit }) => {
    const [formData, setFormData] = useState<AddMemberFormData>({
      first_name: "",
      last_name: "",
      user_email: "",
      membership_type: "member",
    });

    const handleSubmit = () => {
      onSubmit(formData);
      setFormData({
        first_name: "",
        last_name: "",
        user_email: "",
        membership_type: "member",
      });
    };

    return (
      <div className="team-members-management__add-section-form">
        <div className="team-members-management__add-section-form-field">
          <label
            htmlFor="first-name"
            className="team-members-management__add-section-form-label"
          >
            First Name
          </label>
          <PingTextInput
            id="first-name"
            value={formData.first_name}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                first_name: e.target.value,
              }))
            }
          />
        </div>
        <div className="team-members-management__add-section-form-field">
          <label
            htmlFor="last-name"
            className="team-members-management__add-section-form-label"
          >
            Last Name
          </label>
          <PingTextInput
            id="last-name"
            value={formData.last_name}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                last_name: e.target.value,
              }))
            }
          />
        </div>
        <div className="team-members-management__add-section-form-field">
          <label
            htmlFor="email"
            className="team-members-management__add-section-form-label"
          >
            Email
          </label>
          <PingTextInput
            id="email"
            type="email"
            value={formData.user_email}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                user_email: e.target.value,
              }))
            }
          />
        </div>
        <div className="team-members-management__add-section-form-field">
          <label
            htmlFor="role"
            className="team-members-management__add-section-form-label"
          >
            Role
          </label>
          <PingSelectInput
            id="role"
            value={formData.membership_type}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                membership_type: e.target.value,
              }))
            }
            options={[
              { value: "member", label: "Member" },
              { value: "admin", label: "Admin" },
            ]}
          />
        </div>
        <div className="team-members-management__add-section-form-actions">
          <PingButton onClick={onClose} label="Cancel" />
          <PingButton
            onClick={handleSubmit}
            label="Add"
            className="team-members-management__add-button-confirm"
          />
        </div>
      </div>
    );
  },
);

export const TeamMembersManagement = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const teamId = queryParams.get("teamId");
  const { data: teamMembers, isLoading } = useGetTeamMembersQuery(
    Number(teamId),
  );
  const [createTeamMember] = useCreateTeamMemberMutation();
  const [updateTeamMember] = useUpdateTeamMemberMutation();
  const [deleteTeamMember] = useDeleteTeamMemberMutation();
  const { ssoUser } = useAuth();

  // Get team name from URL path
  const teamNameFromPath = window.location.pathname.split("/")[2];
  const teamName = teamNameFromPath
    ? decodeURIComponent(teamNameFromPath.replace(/-/g, " "))
    : "";

  // Panel states
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<{
    id: number;
    name: string;
    email: string;
    membership_type: string;
  } | null>(null);
  const [sortColumns, setSortColumns] = useState<
    { columnKey: string; direction: "ASC" | "DESC" }[]
  >([{ columnKey: "user_email", direction: "ASC" }]);
  const [newlyAddedMemberId, setNewlyAddedMemberId] = useState<number | null>(
    null,
  );
  const [updatedMemberId, setUpdatedMemberId] = useState<number | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const sortedRows = useMemo(() => {
    if (!teamMembers) return [];

    const rows = [...teamMembers];
    if (sortColumns.length > 0) {
      const { columnKey, direction } = sortColumns[0];
      rows.sort((a, b) => {
        const aValue = a[columnKey as keyof typeof a];
        const bValue = b[columnKey as keyof typeof b];

        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;

        const comparison = String(aValue).localeCompare(String(bValue));
        return direction === "ASC" ? comparison : -comparison;
      });
    }
    return rows;
  }, [teamMembers, sortColumns]);

  const handleAddMember = useCallback(
    async (newMember: AddMemberFormData) => {
      try {
        if (teamId) {
          const response = await createTeamMember({
            teamId,
            ...newMember,
          }).unwrap();

          toast.success("Team member added successfully", {
            position: "bottom-center",
          });
          setIsPanelVisible(false);
          setNewlyAddedMemberId(response.id);

          setTimeout(() => {
            setNewlyAddedMemberId(null);
          }, 5000);
        }
      } catch {
        toast.error("Failed to add team member. Please try again.", {
          position: "bottom-center",
        });
      }
    },
    [teamId, createTeamMember],
  );

  const handleDeleteMember = useCallback(
    async (id: number) => {
      try {
        if (
          memberToDelete &&
          (memberToDelete.email === ssoUser?.email ||
            memberToDelete.membership_type === "owner")
        ) {
          toast.error("You cannot delete self or owner", {
            position: "bottom-center",
          });
          setIsPanelVisible(false);
          setMemberToDelete(null);
          return;
        }

        await deleteTeamMember({
          membershipId: id,
        }).unwrap();
        toast.success("Team member deleted successfully", {
          position: "bottom-center",
        });
        setIsPanelVisible(false);
        setMemberToDelete(null);
      } catch {
        toast.error("Failed to delete team member. Please try again.", {
          position: "bottom-center",
        });
      }
    },
    [deleteTeamMember, memberToDelete, ssoUser?.email],
  );

  const handleDeleteTrigger = useCallback(
    (
      membershipId: number,
      membershipType: string,
      firstName: string,
      lastName: string,
      email: string,
    ) => {
      if (
        email === ssoUser?.email ||
        membershipType.toLowerCase() === "owner"
      ) {
        return;
      }
      setMemberToDelete({
        id: membershipId,
        name: `${firstName} ${lastName}`.trim(),
        email: email,
        membership_type: membershipType,
      });
      setIsDeleteModalOpen(true);
    },
    [ssoUser?.email],
  );

  const handleRoleUpdate = useCallback(
    async (membershipId: number, newRole: string, currentEmail: string) => {
      if (currentEmail === ssoUser?.email) {
        return;
      }

      try {
        const updateData = {
          membershipId,
          membership_type: newRole,
          user_email: currentEmail,
        };

        await updateTeamMember(updateData).unwrap();
        setUpdatedMemberId(membershipId);

        setTimeout(() => {
          setUpdatedMemberId(null);
        }, 5000);

        toast.success("Role has been updated for user.", {
          position: "bottom-center",
        });
      } catch {
        toast.error("Failed to update role. Please try again.", {
          position: "bottom-center",
        });
      }
    },
    [updateTeamMember, ssoUser?.email],
  );

  const columns = useMemo(
    () => [
      {
        key: "user_first_name",
        name: "First Name",
        sortable: true,
        resizable: true,
        editable: false,
        minWidth: 150,
      },
      {
        key: "user_last_name",
        name: "Last Name",
        sortable: true,
        resizable: true,
        editable: false,
        minWidth: 150,
      },
      {
        key: "user_email",
        name: "Email",
        sortable: true,
        resizable: true,
        editable: false,
      },
      {
        key: "membership_type",
        name: "Role",
        sortable: true,
        resizable: true,
        editable: false,
        renderCell: ({ row }) => {
          const isDisabled =
            row.membership_type?.toLowerCase() === "owner" ||
            row.user_email === ssoUser?.email;

          return (
            <div
              className={`team-members-management__role-cell ${
                updatedMemberId === row.id
                  ? "team-members-management__highlighted-row"
                  : ""
              }`}
              onClick={(e) => e.stopPropagation()}
              key={`${row.id}-${updatedMemberId}`}
            >
              {isDisabled ? (
                <PingTooltip tooltipRole="label" placement="top">
                  <PingTooltipTrigger asChild>
                    <div>
                      <PingSelectInput
                        value={row.membership_type.toLowerCase()}
                        onChange={(e) =>
                          handleRoleUpdate(
                            row.id,
                            e.target.value,
                            row.user_email,
                          )
                        }
                        options={[
                          { value: "member", label: "Member" },
                          { value: "admin", label: "Admin" },
                        ]}
                        disabled={isDisabled}
                        className="team-members-management__role_change--disabled"
                      />
                    </div>
                  </PingTooltipTrigger>
                  <PingTooltipContent>
                    {row.membership_type?.toLowerCase() === "owner"
                      ? "You cannot change owner role, contact the Ping Vision team."
                      : "Cannot change admin role if self (contact another admin on your team or the Ping Vision team)."}
                  </PingTooltipContent>
                </PingTooltip>
              ) : (
                <PingSelectInput
                  value={row.membership_type.toLowerCase()}
                  onChange={(e) =>
                    handleRoleUpdate(row.id, e.target.value, row.user_email)
                  }
                  options={[
                    { value: "member", label: "Member" },
                    { value: "admin", label: "Admin" },
                  ]}
                  disabled={isDisabled}
                />
              )}
            </div>
          );
        },
      },
      {
        key: "actions",
        name: "",
        width: 50,
        renderCell: ({ row }) => {
          const isDeleteDisabled =
            row.user_email === ssoUser?.email ||
            row.membership_type?.toLowerCase() === "owner";

          const deleteButton = (
            <button
              onClick={() =>
                !isDeleteDisabled &&
                handleDeleteTrigger(
                  row.id,
                  row.membership_type,
                  row.user_first_name,
                  row.user_last_name,
                  row.user_email,
                )
              }
              disabled={isDeleteDisabled}
              className={`team-members-management__delete-button team-members-management__delete-button${isDeleteDisabled ? "--disabled" : ""}`}
              aria-label={`Delete membership - ${row.user_email}`}
            >
              <PingLucideIcon
                iconName="Trash2"
                className="team-members-management__delete-icon"
                style={{ color: isDeleteDisabled ? "#ccc" : "#ff4d4f" }}
              />
            </button>
          );

          return (
            <div className="team-members-management__delete-cell">
              {isDeleteDisabled ? (
                <PingTooltip tooltipRole="label" placement="top">
                  <PingTooltipTrigger asChild>
                    {deleteButton}
                  </PingTooltipTrigger>
                  <PingTooltipContent>
                    {row.membership_type === "owner"
                      ? "You cannot delete owner, contact the Ping Vision team."
                      : row.user_email === ssoUser?.email &&
                          row.membership_type === "admin"
                        ? "Cannot delete admin if self (contact another admin on your team or the Ping Vision team)."
                        : "Cannot delete self."}
                  </PingTooltipContent>
                </PingTooltip>
              ) : (
                deleteButton
              )}
            </div>
          );
        },
      },
    ],
    [updatedMemberId, handleDeleteTrigger, handleRoleUpdate, ssoUser?.email],
  );

  const confirmDelete = useCallback(async () => {
    if (!memberToDelete || !teamId) return;

    await handleDeleteMember(memberToDelete.id);
  }, [memberToDelete, teamId, handleDeleteMember]);

  if (isLoading) {
    return <div>Loading team members...</div>;
  }

  return (
    <PanelGroup direction="horizontal" id="team-members-panel">
      <Panel id="team-members-list" order={1} minSize={60}>
        <div className="team-members-management">
          <div className="team-members-management__header">
            <h1>{teamName} - Manage Team Members</h1>
            <div className="team-members-management__header-actions">
              <button
                className="team-members-management__add-button"
                onClick={() => {
                  setIsPanelVisible(true);
                }}
              >
                Add Member
                <PingLucideIcon
                  iconName="Plus"
                  className="team-members-management__add-icon"
                />
              </button>
            </div>
          </div>
          {sortedRows.length > 0 ? (
            <PingDataGrid
              className="team-members-management__table"
              columns={columns}
              rowHeight={40}
              rows={sortedRows}
              rowKeyGetter={(row) => row.id}
              rowClass={(row) =>
                row.id === newlyAddedMemberId || row.id === updatedMemberId
                  ? "team-members-management__highlighted-row"
                  : ""
              }
              sortColumns={sortColumns}
              onSortColumnsChange={setSortColumns}
            />
          ) : (
            <div className="team-members-management__no-data">
              No team members found
            </div>
          )}
        </div>
      </Panel>
      {isPanelVisible && (
        <>
          <PingPanelResizeHandle orientation="horizontal" width="slim" />
          <Panel
            id="team-members-form"
            order={2}
            defaultSize={30}
            minSize={25}
            maxSize={30}
          >
            <PVPanel>
              <div className="team-members-management__panel-header">
                <h2>Add Team Member</h2>
                <button
                  className="team-members-management__close-button"
                  onClick={() => {
                    setIsPanelVisible(false);
                  }}
                >
                  <PingLucideIcon iconName="X" />
                </button>
              </div>
              <AddMemberSection
                onClose={() => setIsPanelVisible(false)}
                onSubmit={handleAddMember}
              />
            </PVPanel>
          </Panel>
        </>
      )}
      <PingVisionModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setMemberToDelete(null);
        }}
        title="Delete Team Member"
        className={{
          base: "team-members-management__delete-modal",
        }}
        renderContent={() => (
          <>
            <div className="team-members-management__delete-modal-content">
              <p>
                Are you sure you want to delete{" "}
                <strong>{memberToDelete?.name}</strong> ({memberToDelete?.email}
                )?
              </p>
            </div>
            <div className="team-members-management__delete-modal-actions">
              <PingButton
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setMemberToDelete(null);
                }}
                label="Cancel"
              />
              <PingButton
                onClick={() => {
                  confirmDelete();
                  setIsDeleteModalOpen(false);
                }}
                label="Delete"
                className="team-members-management__delete-button-confirm"
              />
            </div>
          </>
        )}
      />
    </PanelGroup>
  );
};
