import {
  omittedSearchFields,
  parsedSearchValues,
} from "src/features/submission-dashboard/queries";
import { updateInboxCount, updateMyIssuesCount } from "../reducers/settings";
import {
  GetSubmissionsQueryParams,
  UpdateCachedData,
} from "ts-types/QueryTypes";
import { addClaimantUpdate } from "../services/claimantUpdatesSlice";
import { addWorkflowUpdate } from "../services/workflowUpdatesSlice";
import { addPendingSubmissionId } from "../services/pendingSubmissionIdsSlice";
import { SovDataType } from "ts-types/DataTypes";
import { RootState } from "services/store";
import { Dispatch } from "@reduxjs/toolkit";
import omit from "lodash/omit";

/**
 * Finds the correct insertion index for a new item in a sorted array.
 *
 * This function is used to determine the correct position for a new submission
 * in a list of sorted submissions based on the specified sort field and direction.
 *
 * @param items - The sorted array of SovDataType items
 * @param newItem - The new item to insert
 * @param direction - The sort order ('asc' or 'desc')
 * @param hasRemainingSovs - Whether there are more items to be loaded from the API
 * @param field - The field to sort by ('received_time' or 'inception_date')
 * @returns - The index where the new item should be inserted, or -1 if it should not be inserted
 */
export const findInsertIndex = (
  items: SovDataType[],
  newItem: SovDataType,
  direction: "asc" | "desc" = "asc",
  hasRemainingSovs: boolean | null,
  field: "received_time" | "inception_date" = "received_time",
) => {
  // Function to compare two items based on the sort field
  const compareItems = (item1: SovDataType, item2: SovDataType): number => {
    // For inception_date, handle null values
    if (field === "inception_date") {
      // If both items have inception_date, compare them
      if (item1.inception_date && item2.inception_date) {
        const date1 = new Date(item1.inception_date).getTime();
        const date2 = new Date(item2.inception_date).getTime();
        return direction === "asc" ? date1 - date2 : date2 - date1;
      }

      // If only item1 has inception_date, it comes first in asc order (or last in desc)
      if (item1.inception_date && !item2.inception_date) {
        return direction === "asc" ? 1 : -1;
      }

      // If only item2 has inception_date, it comes first in asc order (or last in desc)
      if (!item1.inception_date && item2.inception_date) {
        return direction === "asc" ? -1 : 1;
      }

      // If neither has inception_date, fall back to received_time
      const time1 = new Date(item1.received_time).getTime();
      const time2 = new Date(item2.received_time).getTime();
      return direction === "asc" ? time1 - time2 : time2 - time1;
    } else {
      // For received_time, just compare the timestamps
      const time1 = new Date(item1.received_time).getTime();
      const time2 = new Date(item2.received_time).getTime();
      return direction === "asc" ? time1 - time2 : time2 - time1;
    }
  };

  let left = 0,
    right = items.length;

  while (left < right) {
    const mid = Math.floor((left + right) / 2);
    const comparison = compareItems(items[mid], newItem);

    // If comparison > 0, the mid item should come after the new item in the sorted order
    if (comparison > 0) {
      right = mid;
    } else {
      left = mid + 1;
    }
  }

  // If there are more pages to receive from the API and the item is determined to be after the current page list then do not insert
  if (hasRemainingSovs && left === items.length) {
    return -1;
  }
  return left;
};

/**
 * Converts string "true"/"false" values to actual boolean values
 *
 * @param value - The value to convert, which could be a string, boolean, or other type
 * @returns - Boolean if value is "true"/"false" string, otherwise returns the original value
 */
export const parseStringBooleanValue = (value: any): any => {
  if (value === "true") return true;
  if (value === "false") return false;
  return value;
};

/**
 * Extracts and consolidates filter parameters for submission lists from multiple sources.
 *
 * This function determines the filters that should be applied to a submission list by:
 * 1. First checking URL query parameters (highest precedence)
 * 2. Then falling back to the provided query parameters from the API call
 * 3. Finally using default values when neither source provides a value
 *
 * The function handles various filter types including:
 * - Sorting parameters (field and direction)
 * - Data readiness filters (exact, less than, greater than)
 * - Assignment filters (claimed_by_id and null assignment status)
 * - Workflow status filters
 * - Team filters
 *
 * @param arg - The GetSubmissionsQueryParams object containing advancedSearchFields from the API query
 * @returns An object containing all normalized filter values with proper typing
 */
export const getListFilters = (arg: GetSubmissionsQueryParams) => {
  // Extract filters from URL query parameters and API query parameters,
  // giving precedence to URL parameters

  const searchParamsObject = Object.fromEntries(
    new URLSearchParams(location.search),
  );
  const searchValuesWithoutSelected = omit(
    searchParamsObject,
    omittedSearchFields,
  );
  const searchValues = parsedSearchValues(searchValuesWithoutSelected);

  const sortField =
    searchValues?.sort_field ||
    arg?.advancedSearchFields?.sort_field ||
    "received_time";

  const sortDirection =
    searchValues?.sort_direction ||
    arg?.advancedSearchFields?.sort_direction ||
    "asc";

  const readiness =
    searchValues?.readiness || arg?.advancedSearchFields?.readiness;

  const readiness__lt =
    searchValues?.readiness__lt || arg?.advancedSearchFields?.readiness__lt;

  const readiness__gt =
    searchValues?.readiness__gt || arg?.advancedSearchFields?.readiness__gt;

  const claimed_by_id =
    searchValues?.claimed_by_id || arg?.advancedSearchFields?.claimed_by_id;

  const claimed_by_id__isnull =
    searchValues?.claimed_by_id__isnull ||
    arg?.advancedSearchFields?.claimed_by_id__isnull;

  const workflowStatus =
    searchValues?.workflow_status_id ||
    arg?.advancedSearchFields?.workflow_status_id;

  const team_id = searchValues?.team_id || arg?.advancedSearchFields?.team_id;

  const searchText = searchValues?.search || arg?.advancedSearchFields?.search;

  return {
    sortField: sortField as "received_time" | "inception_date",
    sortDirection: sortDirection as "asc" | "desc",
    readiness: readiness as number | null,
    readiness__lt: readiness__lt as number | null,
    readiness__gt: readiness__gt as number | null,
    claimed_by_id: claimed_by_id as number | null,
    claimed_by_id__isnull: parseStringBooleanValue(claimed_by_id__isnull),
    workflowStatus: workflowStatus as number[],
    team_id: team_id as number,
    searchText: searchText as string,
  };
};

/**
 * Determines if a submission meets the readiness score filter criteria.
 *
 * This function evaluates whether a submission's data readiness score matches
 * the filter conditions specified by the user. It handles three types of readiness filters:
 * 1. Exact match (readiness_filter)
 * 2. Less than comparison (readiness__lt_filter)
 * 3. Greater than comparison (readiness__gt_filter)
 *
 * The function applies these rules in order:
 * - If the submission has no readiness score, it passes the filter
 * - If no readiness filters are applied, all submissions pass
 * - For exact match filters, the score must equal the filter value
 * - For less-than filters, the score must be less than the filter value
 * - For greater-than filters, the score must be greater than the filter value
 *
 * @param data_readiness_score - The readiness score of the submission being evaluated
 * @param readiness_filter - Filter for exact match on readiness score
 * @param readiness__lt_filter - Filter for "less than" comparison on readiness score
 * @param readiness__gt_filter - Filter for "greater than" comparison on readiness score
 * @returns True if the submission meets all readiness filter criteria, false otherwise
 */
export const meetsReadinessCondition = (
  data_readiness_score: number | null | undefined,
  readiness_filter: number | null | undefined,
  readiness__lt_filter: number | null | undefined,
  readiness__gt_filter: number | null | undefined,
) => {
  // If the submission has no readiness score, it passes the filter
  if (!data_readiness_score) {
    return true;
  }

  // If no readiness filters are applied, all submissions pass
  if (!readiness_filter && !readiness__lt_filter && !readiness__gt_filter) {
    return true;
  }

  // For exact match filters, the score must equal the filter value
  if (readiness_filter && data_readiness_score !== readiness_filter) {
    return false;
  }

  // For less-than filters, the score must be less than the filter value
  if (readiness__lt_filter && data_readiness_score >= readiness__lt_filter) {
    return false;
  }

  // For greater-than filters, the score must be greater than the filter value
  if (readiness__gt_filter && data_readiness_score <= readiness__gt_filter) {
    return false;
  }

  // If all conditions are met, the submission passes the filter
  return true;
};

/**
 * Determines if a submission meets the assignment filter criteria.
 *
 * This function evaluates whether a submission's assignment status (claimed_by_id)
 * matches the filter conditions specified by the user. It handles several assignment
 * filter scenarios:
 *
 * 1. Show only assigned items (claimed_by_id__isnull = false)
 * 2. Show only unassigned items (claimed_by_id__isnull = true)
 * 3. Show items assigned to a specific user (claimed_by_id = specific ID)
 * 4. No assignment filters (show all items regardless of assignment)
 *
 * The function applies these rules in sequence to determine if the newly created or updated submission
 * should be included in the filtered list.
 *
 * @param item_claimed_by_id - The ID of the user who claimed the submission, if any
 * @param claimed_by_id_filter - Filter for a specific user ID who claimed the submission
 * @param claimed_by_id__isnull_filter - Filter for assignment status (true=unassigned only, false=assigned only)
 * @returns True if the submission meets the assignment filter criteria, false otherwise
 */
export const meetsClaimedByIdCondition = (
  item_claimed_by_id: number | null | undefined,
  claimed_by_id_filter: number | null | undefined,
  claimed_by_id__isnull_filter: boolean | null | undefined,
) => {
  if (!item_claimed_by_id) {
    return true;
  }

  // Case 1: Filter is set to show only assigned items
  if (claimed_by_id__isnull_filter === false) {
    return Boolean(item_claimed_by_id);
  }

  // Case 2: Filter is set to show only unassigned items
  if (claimed_by_id__isnull_filter === true) {
    return Boolean(!item_claimed_by_id);
  }

  // Case 3: No assignee filters are set
  if (!claimed_by_id_filter && !claimed_by_id__isnull_filter) {
    return true;
  }

  // Case 4: Filter by specific assignee ID
  return item_claimed_by_id === claimed_by_id_filter;
};

/**
 * Determines if a submission meets the team filter criteria.
 *
 * This function evaluates whether a submission belongs to the team specified
 * in the filter. The evaluation follows these rules:
 *
 * 1. If the submission has no team assigned, it passes the filter
 * 2. If no team filter is applied, all submissions pass
 * 3. Otherwise, the submission's team ID must match the filter value
 *
 * This function is used as part of the client-side filtering system to determine
 * which newly created or updated submissions should be displayed in team-specific views.
 *
 * @param item_team_id - The team ID of the submission being evaluated
 * @param team_filter - The team ID filter to match against
 * @returns True if the submission meets the team filter criteria, false otherwise
 */
export const meetsTeamCondition = (
  item_team_id: number | null | undefined,
  team_filter: number | null | undefined,
) => {
  // If the submission has no team assigned, it passes the filter
  if (!item_team_id) {
    return true;
  }

  // If no team filter is applied, all submissions pass
  if (!team_filter) {
    return true;
  }

  // The submission's team ID must match the filter value
  return item_team_id === team_filter;
};

/**
 * Determines if a submission meets the workflow status filter criteria.
 *
 * This function evaluates whether a submission's workflow status matches
 * one of the statuses specified in the filter array. The evaluation follows these rules:
 *
 * 1. If the submission has no workflow status, it passes the filter
 * 2. If no workflow status filter is applied (empty array or null), all submissions pass
 * 3. Otherwise, the submission's workflow status must be included in the filter array
 *
 * This function is particularly important for views that filter submissions by their
 * current stage in the workflow process (e.g., "Inbox", "Completed", etc.).
 *
 * @param workflow_status_id - The workflow status ID of the submission being evaluated
 * @param workflow_status_filter - Array of workflow status IDs to match against
 * @returns True if the submission meets the workflow status filter criteria, false otherwise
 */
export const meetsWorkflowStatusCondition = (
  workflow_status_id: number | null | undefined,
  workflow_status_filter: number[] | null | undefined,
) => {
  // If the submission has no workflow status, it passes the filter
  if (!workflow_status_id) {
    return true;
  }

  // If no workflow status filter is applied, all submissions pass
  if (!workflow_status_filter?.length) {
    return true;
  }

  // The submission's workflow status must be included in the filter array
  return workflow_status_filter.includes(workflow_status_id);
};

/**
 * Determines if a submission should be included in a filtered list view.
 *
 * This function serves as the main entry point for client-side filtering of new or updated submissions.
 * It evaluates whether a submission meets all the filter criteria currently applied to a list view.
 * The function works by:
 *
 * 1. Extracting all active filters from the query parameters
 * 2. Evaluating the submission against each filter type individually:
 *    - Data readiness score filters
 *    - Assignment status filters
 *    - Workflow status filters
 *    - Team membership filters
 * 3. Only including the submission if it passes ALL filter conditions
 *
 * This is used for for determining if newly created or updated submissions should be added to the current view.
 *
 * @param item - The SovDataType submission object to evaluate
 * @param arg - The GetSubmissionsQueryParams containing the current query parameters
 * @returns True if the submission meets all filter criteria, false otherwise
 */
export const doesItemFitInList = (
  item: SovDataType,
  arg: GetSubmissionsQueryParams,
) => {
  // Extract all active filters from the query parameters
  const filters = getListFilters(arg);

  if (filters?.searchText) {
    // we really don't know yet how to handle new or changed item insertions, when a search text filter is applied
    return false;
  }

  const {
    readiness,
    readiness__lt,
    readiness__gt,
    claimed_by_id,
    claimed_by_id__isnull,
    workflowStatus,
    team_id,
  } = filters;

  // Evaluate the submission against each filter type
  const meetsReadiness = meetsReadinessCondition(
    item.data_readiness_score,
    readiness,
    readiness__lt,
    readiness__gt,
  );
  const meetsClaimedById = meetsClaimedByIdCondition(
    item.claimed_by_id,
    claimed_by_id,
    claimed_by_id__isnull,
  );
  const meetsWorkflowStatus = meetsWorkflowStatusCondition(
    item.workflow_status_id,
    workflowStatus,
  );
  const meetsTeam = meetsTeamCondition(item.team_id, team_id);

  // The submission must pass ALL filter conditions to be included
  return meetsReadiness && meetsClaimedById && meetsWorkflowStatus && meetsTeam;
};

/**
 * Updates the count badge displayed next to the "My Submissions" navigation item.
 *
 * This function processes WebSocket messages containing changes to the user's assigned issues count.
 * It handles two scenarios:
 * 1. When a submission has a new assignee (new_assignee_info)
 * 2. When a user's work status information changes (my_issues_work_status_info)
 *
 * @param message - The WebSocket message containing changed fields data
 * @param state - The current Redux state
 * @param dispatch - The Redux dispatch function to update the myIssuesCount
 */
export const handleMyIssuesNavItemCountUpdates = (message, state, dispatch) => {
  const clientUser = state.settings?.envData?.user?.id;

  let { changed_fields } = message.data;

  if (changed_fields?.new_assignee_info) {
    const {
      previous_claimed_by_id,
      new_claimed_by_id,
      previous_claimant_my_issues_count,
    } = changed_fields.new_assignee_info;

    // numbers and strings may vary client or backend-wise, so this just ensures that the comparison of user ids is always number-based
    if (+clientUser === +new_claimed_by_id) {
      dispatch(updateMyIssuesCount({ delta: 1 }));
    } else if (clientUser === +previous_claimed_by_id) {
      dispatch(
        updateMyIssuesCount({
          newCount: previous_claimant_my_issues_count,
        }),
      );
    }
  }

  if (
    !changed_fields?.new_assignee_info &&
    changed_fields?.my_issues_work_status_info
  ) {
    const { my_issues_work_status_info } = changed_fields;

    // numbers and strings may vary client or backend-wise, so this just ensures that the comparison of user ids is always number-based
    if (+my_issues_work_status_info?.claimed_by_id === +clientUser) {
      if (my_issues_work_status_info?.my_issues_count) {
        dispatch(
          updateMyIssuesCount({
            newCount: my_issues_work_status_info?.my_issues_count,
          }),
        );
      } else if (my_issues_work_status_info?.my_issues_count_has_incremented) {
        dispatch(
          updateMyIssuesCount({
            delta: 1,
          }),
        );
      }
    }
  }
};

/**
 * Updates the count badge displayed next to the "Inbox" navigation items.
 *
 * This function processes WebSocket messages containing changes to team inbox counts.
 * It handles two update scenarios:
 * 1. Incremental updates when a new item is added to an inbox (inbox_count_has_incremented)
 * 2. Direct updates when the total inbox count changes (inbox_count)
 *
 * Each team has its own inbox with a separate count badge in the sidebar.
 *
 * @param message - The WebSocket message containing new_inbox_count_info data
 * @param dispatch - The Redux dispatch function to update the inboxCounts
 */
export const handleInboxNavItemCountUpdates = (message, dispatch) => {
  /* When submissions change, inbox counts change */
  if (message.type === "submission.update") {
    const { changed_fields } = message.data;

    if (changed_fields?.new_inbox_count_info) {
      const { new_inbox_count_info } = changed_fields;

      if (new_inbox_count_info.inbox_count_has_incremented) {
        dispatch(
          updateInboxCount({
            teamId: new_inbox_count_info.team_id,
            delta: 1,
          }),
        );
      } else if (new_inbox_count_info.inbox_count) {
        dispatch(
          updateInboxCount({
            teamId: new_inbox_count_info.team_id,
            newCount: new_inbox_count_info.inbox_count,
          }),
        );
      }
    }
  } else if (message.type === "submission.create") {
    /* Wen submissions are added, inbox counts increase */
    const newSubmission = message.data;
    dispatch(
      updateInboxCount({
        teamId: newSubmission.data.team_id,
        delta: 1,
      }),
    );
  }
};

/**
 * Tracks changes to submission assignments (claimed_by_id) and records who made these changes.
 *
 * This function processes WebSocket messages containing assignment changes and stores them
 * in the claimantUpdates state. These updates are used to manage the visibility of items in filtered lists:
 *
 * - When viewing a filtered list (e.g., "My Issues"), items that no longer match the filter criteria
 *   are typically removed from the view
 * - However, if the current user is viewing an item they didn't personally reassign, it remains visible
 *   despite no longer matching the filter
 *
 * This ensures users don't lose context when others reassign items they're currently viewing.
 *
 * @param message - The WebSocket message containing new_assignee_info data
 * @param dispatch - The Redux dispatch function to add the claimant update
 */
export const handleAddClaimantUpdate = (message, dispatch) => {
  const { changed_fields } = message.data;

  if (changed_fields?.new_assignee_info?.claim_changed_by_user_id) {
    dispatch(
      addClaimantUpdate({
        submissionId: changed_fields?.new_assignee_info?.data?.id,
        timestamp: Date.now(),
        claimedById: changed_fields.claimed_by_id,
        userId: changed_fields?.new_assignee_info?.claim_changed_by_user_id,
      }),
    );
  }
};
/**
 * Tracks changes to submission workflow statuses and records who made these changes.
 *
 * This function processes WebSocket messages containing workflow status changes and stores them
 * in the workflowUpdates state. These updates are used to manage the visibility of items in filtered lists:
 *
 * - When viewing a filtered list (e.g., "Inbox"), items that no longer match the filter criteria
 *   are typically removed from the view
 * - However, if the current user is viewing an item they didn't personally change the status of,
 *   it remains visible despite no longer matching the filter
 *
 * This ensures users don't lose context when others change the workflow status of items they're currently viewing.
 *
 * @param message - The WebSocket message containing workflow_status_id and status_changed_by_id data
 * @param dispatch - The Redux dispatch function to add the workflow update
 */
export const handleAddWorkflowStatusUpdate = (message, dispatch) => {
  const { changed_fields, id } = message.data;

  if (changed_fields?.workflow_status_id) {
    dispatch(
      addWorkflowUpdate({
        submissionId: id,
        timestamp: Date.now(),
        workflowStatusId: changed_fields.workflow_status_id,
        userId: changed_fields.status_changed_by_id,
      }),
    );
  }
};

/**
 * Determines if and where a newly created submission should be inserted into a filtered list.
 *
 * This function evaluates whether a new submission matches the current list's filter criteria
 * (such as work status and assignee filters) and calculates the appropriate insertion position.
 * The insertion position depends on multiple factors:
 *
 * - Filter criteria: Only submissions matching the current filter set are added
 * - Sort direction: Ascending or descending order affects insertion position
 * - Pagination state: Whether we're at the end of paginated results
 * - Sort field: Whether sorting by inception date or received time
 *
 * If the submission matches the filter criteria but cannot be inserted into the visible list
 * (typically because the user hasn't loaded all data by scrolling to the bottom), the function
 * dispatches addPendingSubmissionId to track this submission in the pendingSubmissionIds slice.
 * These prevent overcounting of the submission list total when submissions are newly updated or created.
 *
 * The pendingSubmissionIds are automatically cleared in their slice when:
 * - The user reaches the end of the list (has_remaining becomes false)
 * - Any query parameter changes except for cursor_id (like filters, search terms, or sort order)
 *
 * @param message - The WebSocket message containing the newly created submission data
 * @param updateCachedData - Function to update the cached submission list
 * @param arg - Arguments containing filter and sort configuration
 * @param state - The current Redux state
 * @param dispatch - The dispatch function for dispatching actions
 */

export const handleListInsertOnSubmissionCreate = (
  message: any,
  updateCachedData: UpdateCachedData,
  arg: GetSubmissionsQueryParams,
  state: RootState,
  dispatch: Dispatch,
) => {
  if (message.type === "submission.create") {
    if (!doesItemFitInList(message.data, arg)) {
      return;
    }

    const newSubmission = message.data;

    const { sortField, sortDirection } = getListFilters(arg);

    updateCachedData((draft) => {
      if (!draft.results.find((item) => item.id === newSubmission.id)) {
        // total
        const totalCount = +(draft.total_size_without_cursors || 0);
        if (totalCount === 99) {
          draft.total_size_without_cursors = "100+";
        } else if (totalCount < 99) {
          draft.total_size_without_cursors = (totalCount + 1).toString();
        }

        const insertIndex = findInsertIndex(
          draft.results,
          newSubmission.data,
          sortDirection,
          state.settings.hasRemainingSovs,
          sortField,
        );
        if (insertIndex !== -1) {
          draft.results.splice(insertIndex, 0, newSubmission.data);
        } else if (state.settings.hasRemainingSovs) {
          // If the item wasn't added because we haven't reached the bottom yet,
          // add it to the pendingSubmissionIds slice.
          dispatch(addPendingSubmissionId(newSubmission.data.id));
        }
      }
    });
  }
};

/**
 * Evaluates whether an updated submission should be added to a list when not already present.
 *
 * When a submission is updated but doesn't exist in the current list view, this function
 * determines if it now matches the list's filter criteria and should be added. The function:
 *
 * - Checks if the updated submission matches filter criteria (e.g., claimed_by_id, workflow status)
 * - Calculates the appropriate insertion position based on sort configuration
 * - Updates the total count of items when a new submission is added
 *
 * The insertion position is influenced by:
 * - Sort direction (ascending/descending)
 * - Sort field (inception date or received time)
 * - Pagination state (whether at the end of results)
 *
 * If the submission matches the filter criteria but cannot be inserted into the visible list
 * (typically because the user hasn't loaded all data by scrolling to the bottom), the function
 * dispatches addPendingSubmissionId to track this submission in the pendingSubmissionIds slice.
 * This prevents overcounting of the submission list total when submissions are newly updated or created.
 *
 * The pendingSubmissionIds are automatically cleared in their slice when:
 * - The user reaches the end of the list (has_remaining becomes false)
 * - Any query parameter changes except for cursor_id (like filters, search terms, or sort order)
 *
 * @param message - The WebSocket message containing the updated submission data
 * @param draft - The draft state of the submission list to be modified
 * @param arg - Arguments containing filter and sort configuration
 * @param state - The current Redux state
 * @param dispatch - The dispatch function for dispatching actions
 */

export const handleListInsertOnSubmissionUpdate = (
  message: any,
  draft: any,
  arg: GetSubmissionsQueryParams,
  state: RootState,
  dispatch: Dispatch,
) => {
  if (message.type === "submission.update") {
    if (!doesItemFitInList(message.data, arg)) {
      return;
    }

    const { changed_fields } = message.data;

    if (
      changed_fields?.new_assignee_info?.data &&
      !state.pendingSubmissionIds.pendingIds.includes(message.data.id)
    ) {
      const newSubmissionItem = {
        ...changed_fields?.new_assignee_info?.data,
      };
      const { sortField, sortDirection } = getListFilters(arg);

      const insertIndex = findInsertIndex(
        draft.results,
        newSubmissionItem,
        sortDirection,
        state.settings.hasRemainingSovs,
        sortField,
      );
      if (insertIndex !== -1) {
        draft.results.splice(insertIndex, 0, newSubmissionItem);
        const totalCount = +(draft.total_size_without_cursors || 0);
        if (totalCount === 99) {
          draft.total_size_without_cursors = "100+";
        } else if (totalCount < 99) {
          draft.total_size_without_cursors = (totalCount + 1).toString();
        }
      } else if (state.settings.hasRemainingSovs) {
        // If the item wasn't added because we haven't reached the bottom yet,
        // add it to the pendingSubmissionIds slice.
        dispatch(addPendingSubmissionId(message.data.id));
      }
    }
  }
};
