import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { rtkQueryErrorLogger, authReducer } from "@repo/ping-react-js";
import settingsReducer from "reducers/settings";
import imboxReducer from "reducers/inbox";
import documentReducer from "reducers/document";
import workflowUpdatesReducer from "./workflowUpdatesSlice";
import claimantUpdatesReducer from "./claimantUpdatesSlice.ts";
import filteredSubmissionIdsReducer from "./filteredSubmissionIdsSlice";
import pendingSubmissionIdsReducer from "./pendingSubmissionIdsSlice";
import selectedSovReducer from "./selectedSovSlice";
import {
  versionApi,
  versionReducer,
  createVersionWebSocket,
} from "./versionApi";
import { api } from "services/pvSlice";

const baseReducers = {
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  settings: settingsReducer,
  inbox: imboxReducer,
  document: documentReducer,
  workflowUpdates: workflowUpdatesReducer,
  filteredSubmissionIds: filteredSubmissionIdsReducer,
  pendingSubmissionIds: pendingSubmissionIdsReducer,
  selectedSov: selectedSovReducer,
  [versionApi.reducerPath]: versionApi.reducer,
  version: versionReducer,
  claimantUpdates: claimantUpdatesReducer,
};

const rootReducer = combineReducers(baseReducers);

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        api.middleware,
        versionApi.middleware,
        rtkQueryErrorLogger,
        createVersionWebSocket(),
      ),
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
