import { createElement } from "react";
import * as LucideIcons from "lucide-react";

import {
  PingAreaIcon,
  PingHideLandmarkIcon,
  PingElevationProfileIcon,
  Ping3DIcon,
  PingPlaceholderIcon,
  PingElevationIcon,
  PingLocationIcon,
  PingAccuracyIconLow,
  PingAccuracyIconMedium,
  PingAccuracyIconHigh,
  PingAccuracyIconVeryHigh,
  PingNotGeocodedIcon,
  PingGeocodedIcon,
} from "../../lib/ping-icons";

const CustomIcons = {
  AreaPing: PingAreaIcon,
  HideLandmarkPing: PingHideLandmarkIcon,
  ElevationProfilePing: PingElevationProfileIcon,
  ThreeDPing: Ping3DIcon,
  PlaceholderPing: PingPlaceholderIcon,
  ElevationPing: PingElevationIcon,
  LocationPing: PingLocationIcon,
  AccuracyLowPing: PingAccuracyIconLow,
  AccuracyMediumPing: PingAccuracyIconMedium,
  AccuracyHighPing: PingAccuracyIconHigh,
  AccuracyVeryHighPing: PingAccuracyIconVeryHigh,
  NotGeocodedPing: PingNotGeocodedIcon,
  GeocodedPing: PingGeocodedIcon,
};

type IconName = keyof typeof LucideIcons | keyof typeof CustomIcons;

const Icon = ({
  iconName,
  ...props
}: { iconName: IconName } & Record<string, any>) => {
  if (!iconName) return null;

  const IconComponent = LucideIcons[iconName];

  if (!IconComponent) {
    if (iconName in CustomIcons) {
      const CustomIconComponent =
        CustomIcons[iconName as keyof typeof CustomIcons];
      return createElement(CustomIconComponent, props);
    }

    console.warn(`Icon "${iconName}" not found in Lucide / Custom library`);
    return null;
  }

  return createElement(IconComponent as React.ComponentType<any>, props);
};

export { Icon };
